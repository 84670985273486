import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './DashboardWijtmanPlanningMaken.css';
import {IoArrowBackSharp} from "react-icons/io5";

function DashboardWijtmanPlanningMaken({ goToPlanlijst, onBack }) {
    const API_BASE_URL = process.env.REACT_APP_API_HOST;

    const [vehicles, setVehicles] = useState([]);
    const [selectedVehicle, setSelectedVehicle] = useState('');
    const [machinists, setMachinists] = useState([]);
    const [secondMen, setSecondMen] = useState([]);
    const [selectedMachinist, setSelectedMachinist] = useState(''); // Stores the phone number of the selected machinist
    const [selectedMachinistUuid, setSelectedMachinistUuid] = useState(''); // Stores the UUID of the selected machinist
    const [selectedMachinistEmail, setSelectedMachinistEmail] = useState('');
    const [selectedSecondMan, setSelectedSecondMan] = useState(''); // Stores the phone number of the selected second man
    const [selectedSecondManUuid, setSelectedSecondManUuid] = useState(''); // Stores the UUID of the selected second man
    const [selectedSecondManEmail, setSelectedSecondManEmail] = useState('');
    const [availableCustomers, setAvailableCustomers] = useState([]);
    const [availableTaskModules, setAvailableTaskModules] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState([]);
    const [selectedTaskModule, setSelectedTaskModule] = useState([]);
    const [graveNumber, setGraveNumber] = useState([]);
    const [selectedCustomerArrivalTime, setSelectedCustomerArrivalTime] = useState([]);
    const [selectedCustomerDepartureTime, setSelectedCustomerDepartureTime] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [executionDate, setExecutionDate] = useState(''); // New state for Uitvoerdatum
    const [completed, setCompleted] = useState(false);
    const [filteredTasks, setFilteredTasks] = useState({}); // Store filtered tasks by customer index

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');
                const headers = { Authorization: `Bearer ${token}` };

                const [vehiclesRes, personnelRes, customersRes, taskModulesRes] = await Promise.all([
                    axios.get(`${API_BASE_URL}/voertuig/allavailable`, { headers }),
                    axios.get(`${API_BASE_URL}/personeel/allavailable`, { headers }),
                    axios.get(`${API_BASE_URL}/klant/all`, { headers }),
                    axios.get(`${API_BASE_URL}/taak/allavailable`, { headers }),
                ]);

                setVehicles(vehiclesRes.data.map(v => v.vehicleNickname));

                const machinistsList = [], secondMenList = [];
                personnelRes.data.forEach(p => {
                    const roles = p.jobRole?.split(', ') || [];
                    if (roles.includes('Machinist')) machinistsList.push(p);
                    if (roles.includes('Tweede man')) secondMenList.push(p);
                });
                setMachinists(machinistsList);
                setSecondMen(secondMenList);

                setAvailableCustomers(customersRes.data.map(c => c.klantNaam));
                setAvailableTaskModules(taskModulesRes.data.map(t => ({
                    uuid: t.uuid, // Ensure UUID is included here
                    taskName: t.taskName,
                    taskDuration: t.taskDuration,
                    customerTaskList: t.customerTaskList
                })));

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [API_BASE_URL]);

// Handle change for both Machinist and Second Man
    const handleChange = (setSelectedPhone, setSelectedUuid, setEmail, data) => (event) => {
        const selectedUuid = event.target.value; // Get the selected UUID
        const selectedObj = data.find(item => item.uuid === selectedUuid); // Find the selected object

        // Update selected UUID, phone number, and email
        setSelectedUuid(selectedUuid);
        setSelectedPhone(selectedObj?.phoneNumber || ''); // Store phone number
        setEmail(selectedObj?.email || ''); // Store email
    };

    const handleMultipleChange = (setState) => (event) => setState([...event.target.selectedOptions].map(o => o.value));

    const handleCustomerChange = (index, value) => {
        const updatedCustomers = [...customers];
        updatedCustomers[index] = { ...updatedCustomers[index], selectedCustomer: value };
        setCustomers(updatedCustomers);

        // Filter tasks specific to the selected customer and update filteredTasks state
        const customerFilteredTasks = availableTaskModules.filter(task =>
            task.customerTaskList === value // Ensure this matches the customer name
        );

        console.log(`Filtering tasks for customer ${value}:`, customerFilteredTasks); // Debug log

        setFilteredTasks(prevFilteredTasks => ({
            ...prevFilteredTasks,
            [index]: customerFilteredTasks // Set tasks for this specific customer index
        }));
    };


    const handleTaskModuleChange = (cIndex, tIndex, uuid) => {
        const updated = [...customers];

        // Find the selected task by UUID
        const selectedTask = availableTaskModules.find(task => task.uuid === uuid);

        if (selectedTask) {
            updated[cIndex].taskModules[tIndex] = {
                ...updated[cIndex].taskModules[tIndex],
                selectedTaskModule: selectedTask.taskName,  // Display task name
                selectedTaskUuid: uuid,                      // Store UUID for reference
                selectedTaskDuration: selectedTask.taskDuration
            };
        }

        setCustomers(updated);
    };

    const handleGraveNumberChange = (cIndex, tIndex, value) => {
        const updated = [...customers];
        updated[cIndex].taskModules[tIndex].graveNumber = value;
        setCustomers(updated);
    };


    const handleTimeChange = (index, field, value) => {
        const updated = [...customers];
        updated[index][field] = value;
        setCustomers(updated);
    };

    const addNewCustomer = () => setCustomers([...customers, {
        selectedCustomer: '',
        taskModules: [{ selectedTaskModule: '', selectedTaskDuration: '', graveNumber: ''}],
        selectedCustomerArrivalTime: '',
        selectedCustomerDepartureTime: ''
    }]);

    const addTaskModule = (index) => {
        const updated = [...customers];
        updated[index].taskModules.push({ selectedTaskModule: '', selectedTaskDuration: '',  graveNumber: '' });
        setCustomers(updated);
    };

    const handleAfronden = async () => {
        try {
            const token = localStorage.getItem('token');
            const headers = { Authorization: `Bearer ${token}` };

            // Prepare the PlanTaak data
            const planTaakData = {
                executionDate: executionDate,
                selectedVehicle: selectedVehicle.length > 0 ? selectedVehicle[0] : '',
                selectedMachinist: selectedMachinist,
                selectedSecondMan: selectedSecondMan,
                selectedMachinistEmail: selectedMachinistEmail,
                selectedSecondManEmail: selectedSecondManEmail
            };
            console.log('Posting PlanTaak Data:', planTaakData);

            const planTaakResponse = await axios.post(
                `${API_BASE_URL}/plantaak/create`,
                planTaakData,
                { headers }
            );

            const PlanTaakUuid = planTaakResponse.data.PlanTaakUuid || planTaakResponse.data.uuid || planTaakResponse.data.id;
            console.log('PlanTaak Response:', planTaakResponse.data);

            // Validate UUID
            if (!PlanTaakUuid) {
                throw new Error('PlanTaak UUID is undefined. Check API response structure.');
            }

            // Store PlanTaak UUID in localStorage
            localStorage.setItem('PlanTaakUuid', PlanTaakUuid);

            // Retrieve PlanTaakUuid from localStorage
            const retrievedPlanTaakUuid = localStorage.getItem('PlanTaakUuid');

            // Create KlantTaak for each customer
            for (let customer of customers) {
                const klantTaakData = {
                    selectedCustomer: customer.selectedCustomer,
                    selectedCustomerArrivalTime: customer.selectedCustomerArrivalTime,
                    selectedCustomerDepartureTime: customer.selectedCustomerDepartureTime,
                    planTaakUuid: retrievedPlanTaakUuid
                };
                console.log('Posting KlantTaak Data for Customer:', klantTaakData);

                const klantTaakResponse = await axios.post(
                    `${API_BASE_URL}/klanttaak/create`,
                    klantTaakData,
                    { headers }
                );

                const KlantTaakUuid = klantTaakResponse.data.KlantTaakUuid || klantTaakResponse.data.uuid || klantTaakResponse.data.id;
                console.log('KlantTaak Response for Customer:', klantTaakResponse.data);

                // Validate UUID
                if (!KlantTaakUuid) {
                    throw new Error(`KlantTaak UUID is undefined for customer ${customer.selectedCustomer}. Check API response structure.`);
                }

                // Create GrafTaak for each taskModule of the customer
                for (let taskModule of customer.taskModules) {
                    const grafTaakData = {
                        selectedTaskModule: taskModule.selectedTaskModule,
                        selectedTaskDuration: taskModule.selectedTaskDuration, // Ensure task duration is used directly here
                        graveNumber: taskModule.graveNumber,
                        klantTaakUuid: KlantTaakUuid,
                        planTaakUuid: retrievedPlanTaakUuid
                    };
                    console.log('Posting GrafTaak Data for TaskModule:', grafTaakData);

                    const grafTaakResponse = await axios.post(
                        `${API_BASE_URL}/graftaak/create`,
                        grafTaakData,
                        { headers }
                    );

                    console.log('GrafTaak Response for TaskModule:', grafTaakResponse.data);
                }
            }

            // Resetting state fields after successful post
            resetForm();
            setCompleted(true);
        } catch (error) {
            console.error('Error completing the plan:', error);
        }
    };

// Function to reset the form fields
    const resetForm = () => {
        setExecutionDate('');
        setSelectedVehicle([]);
        setSelectedMachinist('');
        setSelectedMachinistUuid('');
        setSelectedMachinistEmail('');
        setSelectedSecondMan('');
        setSelectedSecondManUuid('');
        setSelectedSecondManEmail('');
        setCustomers([]); // Reset customers to an empty array or initial structure
        setCompleted(false); // Optionally reset completed status
    };

    return (
        <div className="dashboard">
            <button
                onClick={onBack}
                style={{
                    background: 'darkblue',
                    fontSize: '2.5rem',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    padding: '10px' // Optional for spacing
                }}>
                <IoArrowBackSharp style={{ fontSize: '2.5rem' }} />
                Terug
            </button>
            <br/>
            <h1 className="dashboard-title">Plantaak aanmaken</h1>

            {/* New executionDate (Uitvoerdatum) Section */}
            <div className="form-group">
                <label>Uitvoerdatum:</label>
                <input
                    type="date"
                    value={executionDate}
                    onChange={(e) => setExecutionDate(e.target.value)}
                    required
                />
            </div>

            <div className="form-group">
                <select multiple value={selectedVehicle} onChange={handleMultipleChange(setSelectedVehicle)}>
                    {vehicles.map((v, i) => <option key={i} value={v}>{v}</option>)}
                </select>
            </div>
            <div className="form-group">
                <select
                    value={selectedMachinistUuid} // Bind to the UUID state
                    onChange={handleChange(setSelectedMachinist, setSelectedMachinistUuid, setSelectedMachinistEmail, machinists)}
                >
                    <option value="">--Selecteer Machinist--</option>
                    {machinists.map((m) => (
                        <option key={m.uuid} value={m.uuid}>{m.phoneNumber}</option> // Set UUID as the value
                    ))}
                </select>
            </div>
            <div className="form-group">
                <select
                    value={selectedSecondManUuid} // Bind to the UUID state
                    onChange={handleChange(setSelectedSecondMan, setSelectedSecondManUuid, setSelectedSecondManEmail, secondMen)}
                >
                    <option value="">--Selecteer 2e Man--</option>
                    {secondMen.map((s) => (
                        <option key={s.uuid} value={s.uuid}>{s.phoneNumber}</option> // Set UUID as the value
                    ))}
                </select>
            </div>
            <div className="customers-section">
                <h3>Klant en Taakmodules</h3>
                {customers.map((c, cIndex) => (
                    <div key={cIndex} className="customer-section">
                        <div className="form-group">
                            <select
                                value={c.selectedCustomer || ""}
                                onChange={(e) => handleCustomerChange(cIndex, e.target.value)}
                            >
                                <option value="">--Selecteer Klant--</option>
                                {availableCustomers.map((cust, i) => (
                                    <option key={i} value={cust}>{cust}</option>
                                ))}
                            </select>
                        </div>
                        <div className="time-group">
                            <div className="form-group">
                                <label>Aankomsttijd:</label>
                                <input
                                    type="time"
                                    value={c.selectedCustomerArrivalTime}
                                    onChange={(e) => handleTimeChange(cIndex, 'selectedCustomerArrivalTime', e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label>Vertrektijd:</label>
                                <input
                                    type="time"
                                    value={c.selectedCustomerDepartureTime}
                                    onChange={(e) => handleTimeChange(cIndex, 'selectedCustomerDepartureTime', e.target.value)}
                                />
                            </div>
                        </div>
                        {c.taskModules.map((t, tIndex) => (
                            <div key={tIndex} className="task-module">
                                <div className="form-group">
                                    <label>Taak {tIndex + 1}:</label>
                                    <select
                                        value={t.selectedTaskUuid || ""}  // Bind to selectedTaskUuid to show selection
                                        onChange={(e) => handleTaskModuleChange(cIndex, tIndex, e.target.value)}
                                        disabled={!c.selectedCustomer}
                                    >
                                        <option value="">--Select Module--</option>
                                        {(filteredTasks[cIndex] || []).map((task) => (
                                            <option key={task.uuid} value={task.uuid}>
                                                {task.taskName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        value={t.graveNumber || ""}
                                        placeholder="Grafnummer (optioneel)"
                                        onChange={(e) => handleGraveNumberChange(cIndex, tIndex, e.target.value)}
                                    />
                                </div>

                            </div>
                        ))}
                        <button onClick={() => addTaskModule(cIndex)} className="db-planning-maken-taak-toevoegen">Taak toevoegen</button>
                    </div>
                ))}
                <button onClick={addNewCustomer} className="db-planning-maken-adres-toevoegen">Adres toevoegen</button>
                <br/>
                <br/>
            </div>
            {!completed && (
                <button
                    onClick={handleAfronden}
                    disabled={!selectedVehicle.length || !selectedMachinist || !selectedSecondMan || !customers.length || !executionDate}
                    className="db-planning-maken-afronden-knop"
                >
                    Afronden
                </button>
            )}

            {completed && (
                <>
                    <button
                        onClick={handleAfronden}
                        disabled={!selectedVehicle.length || !selectedMachinist || !selectedSecondMan || !customers.length || !executionDate}
                        className="db-planning-maken-afronden-knop"
                    >
                        Afronden
                    </button>
                    <button onClick={goToPlanlijst} className="db-planning-maken-overzicht">Ga naar planning overzicht</button>
                </>
            )}
        </div>
    );
}

export default DashboardWijtmanPlanningMaken;


