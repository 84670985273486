import React, { useState } from 'react';
import './DashboardWijtmanVoertuigAanmaken.css';
import axios from "axios";
import {IoArrowBackSharp} from "react-icons/io5"; // For custom styles

function DashboardWijtmanVoertuigAanmaken({onBack}) {
    const API_BASE_URL = process.env.REACT_APP_API_HOST;

    // State for the form fields
    const [vehicleNickname, setVehicleNickname] = useState('');
    const [licensePlate, setLicensePlate] = useState('');
    const [vehicleType, setVehicleType] = useState('');
    const [yearOfConstruction, setYearOfConstruction] = useState('');
    const [error, setError] = useState(null); // State for error handling
    const [successMessage, setSuccessMessage] = useState(''); // State for success message

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        const vehicleData = {
            vehicleNickname,
            licensePlate,
            vehicleType,
            yearOfConstruction
        };

        try {
            const response = await axios.post(`${API_BASE_URL}/voertuig/create`, vehicleData);
            console.log(response.data); // Handle response here (e.g., show success message)
            setSuccessMessage('Machine successfully created!');
            // Reset the form after submission
            setVehicleNickname('');
            setLicensePlate('');
            setVehicleType('');
            setYearOfConstruction('');
        } catch (error) {
            console.error('Error creating vehicle:', error);
            setError('Failed to create vehicle. Please try again.');
        }
    };


    return (
        <div className="DashboardWijtmanVoertuigAanmaken">
            <button
                onClick={onBack}
                style={{
                    background: 'darkblue',
                    fontSize: '2.5rem',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    padding: '10px' // Optional for spacing
                }}>
                <IoArrowBackSharp style={{ fontSize: '2.5rem' }} />
                Terug
            </button>
            <br/>
            <h1>Aanmaken</h1>
            {error && <p className="error-message">{error}</p>}
            {successMessage && <p className="success-message">{successMessage}</p>}
            <form onSubmit={handleSubmit}>
                <div>
                    <label>
                        Bijnaam voertuig:
                        <input
                            type="text"
                            value={vehicleNickname}
                            onChange={(e) => setVehicleNickname(e.target.value)}

                        />
                    </label>
                </div>

                <div>
                    <label>
                        Kenteken:
                        <input
                            type="text"
                            value={licensePlate}
                            onChange={(e) => setLicensePlate(e.target.value)}

                        />
                    </label>
                </div>

                <div className="vehicle-type-selection">
                    <p>Soort voertuig:</p>
                    <div className="vehicle-type-boxes">
                        {['Trekker', 'Aanhanger'].map((type) => (
                            <div
                                key={type}
                                className={`box ${vehicleType === type ? 'selected' : ''}`}
                                onClick={() => setVehicleType(type)}
                            >
                                {type}
                            </div>
                        ))}
                    </div>
                </div>

                <div>
                    <label>
                        Bouwjaar:
                        <input
                            type="number"
                            value={yearOfConstruction}
                            onChange={(e) => setYearOfConstruction(e.target.value)}

                        />
                    </label>
                </div>

                <button type="submit" className="db-voertuig-aanmaken-verstuur">Verstuur</button>
            </form>
        </div>
    );
}

export default DashboardWijtmanVoertuigAanmaken;
