import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './DashboardDeleteKlant.css';
import {IoArrowBackSharp} from "react-icons/io5"; // Import the CSS file for styling

function DashboardDeleteKlant({ onBack }) {
    const API_BASE_URL = process.env.REACT_APP_API_HOST;
    const [klanten, setKlanten] = useState([]);
    const [selectedKlant, setSelectedKlant] = useState('');

    // Fetch all customers on component mount
    useEffect(() => {
        axios.get(`${API_BASE_URL}/klant/all`)
            .then(response => {
                setKlanten(response.data);
            })
            .catch(error => {
                console.error('Error fetching klanten:', error);
            });
    }, [API_BASE_URL]);

    // Function to delete the selected customer
    const deleteKlant = () => {
        if (!selectedKlant) {
            alert('Selecteer een klant om te verwijderen.');
            return;
        }

        const token = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };

        axios.delete(`${API_BASE_URL}/klant/${selectedKlant}`, { headers })
            .then(response => {
                alert('Klant succesvol verwijderd!');
                // Refresh the klant list after deletion
                setKlanten(klanten.filter(klant => klant.uuid !== selectedKlant));
                setSelectedKlant(''); // Reset the selected klant
            })
            .catch(error => {
                console.error('Error deleting klant:', error);
            });
    };

    return (
        <div className="dashboard-delete-klant">
            <button onClick={onBack}><IoArrowBackSharp style={{ fontSize: '18px' }} /></button>
            <h1 className="dashboard-delete-title">Verwijder Klant</h1>

            <div className="dashboard-delete-form">
                <label className="dashboard-delete-label">
                    Selecteer een klant:
                    <select
                        className="dashboard-delete-select"
                        value={selectedKlant}
                        onChange={e => setSelectedKlant(e.target.value)}
                    >
                        <option value="">Selecteer een klant</option>
                        {klanten.map(klant => (
                            <option key={klant.uuid} value={klant.uuid}>
                                {klant.klantNaam}
                            </option>
                        ))}
                    </select>
                </label>

                <button className="dashboard-delete-button" onClick={deleteKlant}>
                    Verwijderen
                </button>
            </div>
        </div>
    );
}

export default DashboardDeleteKlant;
