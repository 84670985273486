import React, { useState } from 'react';
import axios from 'axios'; // Import Axios
import './VoorwerpMelden.css';

function VoorwerpMelden() {
    const API_BASE_URL = process.env.REACT_APP_API_HOST; // Ensure this is set in your .env file

    const [reportType, setReportType] = useState(''); // Changed to reportType
    const [reportWhat, setReportWhat] = useState(''); // Changed to reportWhat
    const [description, setDescription] = useState(''); // Changed to description
    const [reportWhere, setReportWhere] = useState(''); // Changed to reportWhere

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Prepare data to send
        const data = {
            reportType,
            reportWhat,
            description,
            reportWhere,
        };

        // Setup headers (if needed)
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}` // Assuming you're storing a token in local storage
        };

        try {
            // Make POST request
            const response = await axios.post(`${API_BASE_URL}/report/create`, data, { headers });
            console.log("Response from server:", response.data);
            alert("Melding succesvol verzonden!");

            // Reset de invoervelden na succesvolle verzending
            setReportWhat('');
            setReportType('');
            setDescription('');
            setReportWhere('');
        } catch (error) {
            console.error("Er is een fout opgetreden:", error);
            alert("Er is een fout opgetreden bij het verzenden van de melding. Probeer het opnieuw.");
        }
    };

    return (
        <div className="voorwerp-melden-container">
            <h2>Vergeten / Kapot</h2>
            <p style={{ fontWeight: 'bold' }}>
                Meld een vergeten of kapot geval.
            </p>
            <br/>

            <form onSubmit={handleSubmit} className="voorwerp-melden-form">
                <div className="selectable-section">
                    <p>Type melding</p>
                    <div className="selectable-boxes">
                        {["Vergeten", "Kapot"].map((type) => (
                            <div
                                key={type}
                                className={`selectable-box ${reportType === type ? "selected" : ""}`}
                                onClick={() => setReportType(type)} // Updated to setReportType
                            >
                                {type}
                            </div>
                        ))}
                    </div>
                </div>

                <label>
                    Wat? (verplicht)
                    <input
                        type="text"
                        value={reportWhat} // Updated to reportWhat
                        onChange={(e) => setReportWhat(e.target.value)} // Updated to setReportWhat
                        placeholder="Vul hier in waar het om gaat"
                        required
                    />
                </label>

                <label>
                    Waar?
                    <input
                        type="text"
                        value={reportWhere} // Updated to reportWhere
                        onChange={(e) => setReportWhere(e.target.value)} // Updated to setReportWhere
                        placeholder="Locatie waar het vergeten of kapot is"
                    />
                </label>

                <button type="submit" className="voorwerp-melden-send-report-button">Inzenden</button>
            </form>
        </div>
    );
}

export default VoorwerpMelden;
