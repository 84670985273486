import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaRegArrowAltCircleUp, FaRegArrowAltCircleDown } from 'react-icons/fa';
import { ImBin } from 'react-icons/im';
import './PlantakenOverzicht.css';
import {IoArrowBackSharp} from "react-icons/io5";

function PlantakenOverzicht({onBack}) {
    const API_BASE_URL = process.env.REACT_APP_API_HOST;
    const [planningData, setPlanningData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [grafTaakData, setGrafTaakData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchPlanningAndCustomerData = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            setError('No token found. Please log in.');
            setLoading(false);
            return;
        }

        try {
            const planningResponse = await axios.get(`${API_BASE_URL}/plantaak/allavailable`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // Format the executionDate for each item in the planning data
            const formattedPlanningData = planningResponse.data.map(item => {
                const formattedDate = new Date(item.executionDate).toLocaleDateString('nl-NL', {
                    weekday: 'long',
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric'
                });

                // Capitalize the first letter of the formatted date
                const capitalizedDate = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

                return {
                    ...item,
                    executionDate: capitalizedDate
                };
            });

            setPlanningData(formattedPlanningData);

            const customerResponse = await axios.get(`${API_BASE_URL}/klanttaak/allavailable`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setCustomerData(customerResponse.data);

            const grafTaakResponse = await axios.get(`${API_BASE_URL}/graftaak/allavailable`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setGrafTaakData(grafTaakResponse.data);

            setLoading(false);
        } catch (err) {
            console.error(err.response ? err.response.data : err.message);
            setError(err.response ? err.response.data.message : err.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPlanningAndCustomerData();
    }, [API_BASE_URL]);

    // Enable Plantaak and corresponding KlantTaak and GrafTaak
    const enablePlanTaak = async (uuid) => {
        const token = localStorage.getItem('token');

        // Enable Plantaak
        await axios.put(`${API_BASE_URL}/plantaak/enable/${uuid}`, {}, {
            headers: { Authorization: `Bearer ${token}` },
        });

        // Enable corresponding KlantTaaks
        const klantTaaksToEnable = customerData.filter(customer => customer.planTaakUuid === uuid);
        for (const klantTaak of klantTaaksToEnable) {
            await enableKlantTaak(klantTaak.uuid);
        }

        fetchPlanningAndCustomerData();
    };

    // Disable Plantaak and corresponding KlantTaak and GrafTaak
    const disablePlanTaak = async (uuid) => {
        const token = localStorage.getItem('token');

        // Disable Plantaak
        await axios.put(`${API_BASE_URL}/plantaak/disable/${uuid}`, {}, {
            headers: { Authorization: `Bearer ${token}` },
        });

        // Disable corresponding KlantTaaks
        const klantTaaksToDisable = customerData.filter(customer => customer.planTaakUuid === uuid);
        for (const klantTaak of klantTaaksToDisable) {
            await disableKlantTaak(klantTaak.uuid);
        }

        fetchPlanningAndCustomerData();
    };

    // Delete Plantaak and corresponding KlantTaak and GrafTaak
    const deletePlanTaak = async (uuid) => {
        const token = localStorage.getItem('token');

        // Fetch KlantTaaks associated with the Plantaak
        const klantTaaksToDelete = customerData.filter(customer => customer.planTaakUuid === uuid);

        // Delete KlantTaaks and their GrafTaaks
        for (const klantTaak of klantTaaksToDelete) {
            await deleteKlantTaak(klantTaak.uuid);
        }

        // Delete Plantaak
        await axios.delete(`${API_BASE_URL}/plantaak/${uuid}`, {
            headers: { Authorization: `Bearer ${token}` },
        });

        fetchPlanningAndCustomerData();
    };

    const enableKlantTaak = async (uuid) => {
        const token = localStorage.getItem('token');
        await axios.put(`${API_BASE_URL}/klanttaak/enable/${uuid}`, {}, {
            headers: { Authorization: `Bearer ${token}` },
        });

        // Optionally enable associated GrafTaaks
        const grafTaaksToEnable = grafTaakData.filter(graf => graf.klantTaakUuid === uuid);
        for (const grafTaak of grafTaaksToEnable) {
            await enableGrafTaak(grafTaak.uuid);
        }
    };

    const disableKlantTaak = async (uuid) => {
        const token = localStorage.getItem('token');
        await axios.put(`${API_BASE_URL}/klanttaak/disable/${uuid}`, {}, {
            headers: { Authorization: `Bearer ${token}` },
        });

        // Optionally disable associated GrafTaaks
        const grafTaaksToDisable = grafTaakData.filter(graf => graf.klantTaakUuid === uuid);
        for (const grafTaak of grafTaaksToDisable) {
            await disableGrafTaak(grafTaak.uuid);
        }
    };

    const deleteKlantTaak = async (uuid) => {
        const token = localStorage.getItem('token');

        // Delete GrafTaaks associated with KlantTaak
        const grafTaaksToDelete = grafTaakData.filter(graf => graf.klantTaakUuid === uuid);
        for (const grafTaak of grafTaaksToDelete) {
            await deleteGrafTaak(grafTaak.uuid);
        }

        // Delete KlantTaak
        await axios.delete(`${API_BASE_URL}/klanttaak/${uuid}`, {
            headers: { Authorization: `Bearer ${token}` },
        });
    };

    const enableGrafTaak = async (uuid) => {
        const token = localStorage.getItem('token');
        await axios.put(`${API_BASE_URL}/graftaak/enable/${uuid}`, {}, {
            headers: { Authorization: `Bearer ${token}` },
        });
    };

    const disableGrafTaak = async (uuid) => {
        const token = localStorage.getItem('token');
        await axios.put(`${API_BASE_URL}/graftaak/disable/${uuid}`, {}, {
            headers: { Authorization: `Bearer ${token}` },
        });
    };

    const deleteGrafTaak = async (uuid) => {
        const token = localStorage.getItem('token');
        await axios.delete(`${API_BASE_URL}/graftaak/${uuid}`, {
            headers: { Authorization: `Bearer ${token}` },
        });
    };

    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    if (error) {
        return <div className="error">Error: {error}</div>;
    }


    return (
        <div className="dashboard-planning">
            <button
                onClick={onBack}
                style={{
                    background: 'darkblue',
                    fontSize: '2.5rem',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    padding: '10px' // Optional for spacing
                }}>
                <IoArrowBackSharp style={{ fontSize: '2.5rem' }} />
                Terug
            </button>
            <br/>
            <h1 className="dashboard-title">Planning overzicht</h1>
            <ul className="planning-list">
                {planningData.map((planningItem) => (
                    <li key={planningItem.uuid} className="planning-item">
                        <div className="align-plantaken-overzicht-status-end">
                            <div className="plantaken-overzicht-button-container">
                                <button onClick={() => enablePlanTaak(planningItem.uuid)} className="enable-plantaak">
                                    <FaRegArrowAltCircleUp/></button>
                                <button onClick={() => disablePlanTaak(planningItem.uuid)} className="disable-plantaak">
                                    <FaRegArrowAltCircleDown/></button>
                                <button onClick={() => deletePlanTaak(planningItem.uuid)} className="delete-plantaak">
                                    <ImBin/></button>
                            </div>
                            <span className={`status ${planningItem.enabled ? "online" : "offline"}`}>
                                <span className="status-light">
                                </span>
                                {planningItem.enabled ? "online" : "offline"}
                                </span>
                        </div>





                        <div className="planning-details">
                            <div className="plannerrole-planning-item-header">
                                {planningItem.executionDate}
                            </div>
                            <span
                                className="planning-detail"><strong>Auto:</strong> {planningItem.selectedVehicle}</span>
                            <span
                                className="planning-detail"><strong>Machinist:</strong> {planningItem.selectedMachinist}</span>
                            <span
                                className="planning-detail"><strong>Tweede man:</strong> {planningItem.selectedSecondMan}</span>
                        </div>
                        <div className="customer-list">
                            <ul className="customer-list-items">
                                {customerData
                                    .filter(customer => customer.planTaakUuid === planningItem.uuid)
                                    .map(filteredCustomer => (
                                        <li key={filteredCustomer.uuid} className="customer-list-item">
                                            <br/>
                                            <span className="customer-name">{filteredCustomer.selectedCustomer}</span>
                                            <br/>
                                            <div className="align-customer-time-row">
                                                <span
                                                    className="customer-time"><strong>Aankomst:</strong> {filteredCustomer.selectedCustomerArrivalTime}</span>
                                                <span
                                                    className="customer-time"><strong>Vertrek:</strong> {filteredCustomer.selectedCustomerDepartureTime}</span>
                                            </div>
                                            <br/>
                                            <ul className="graf-task-list">
                                                {grafTaakData
                                                    .filter(graf => graf.klantTaakUuid === filteredCustomer.uuid)
                                                    .map(graf => (
                                                        <li key={graf.uuid} className="graf-task-item">
                                                            <span className="planning-detail">
                                                                <strong>{graf.selectedTaskModule}</strong>
                                                                <span>Graf: {graf.graveNumber}</span>
                                                                {graf.selectedTaskDuration} minuten
                                                            </span>
                                                        </li>
                                                    ))}
                                                {grafTaakData.filter(graf => graf.klantTaakUuid === filteredCustomer.uuid).length === 0 && (
                                                    <li className="no-graf-task">No corresponding GrafTaak found.</li>
                                                )}
                                            </ul>
                                        </li>
                                    ))}
                                {customerData.filter(customer => customer.planTaakUuid === planningItem.uuid).length === 0 && (
                                    <li className="no-customer">No corresponding KlantTaak found.</li>
                                )}
                            </ul>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default PlantakenOverzicht;

