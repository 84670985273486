import React, { useState, useEffect } from 'react';
import './Footer.css';
import Logo from "../assets/Logo.png";
import SendWhatsAppMessage from "./SendWhatsAppMessage";
import { useLocation } from "react-router-dom";

function Footer() {
    const location = useLocation();
    const [isFooterVisible, setIsFooterVisible] = useState(true);

    useEffect(() => {
        // Check if the current path starts with '/dashboard'
        if (location.pathname.startsWith('/dashboard')) {
            setIsFooterVisible(false); // Hide footer for dashboard routes
        } else {
            setIsFooterVisible(true); // Show footer for other routes
        }
    }, [location.pathname]); // Run effect on path change

    return (
        isFooterVisible && ( // Conditionally render the footer
            <footer className="footer">
                <div className="footer-content">
                    <div className="footer-logo">
                        <img src={Logo} alt="Logo" />
                    </div>
                    <div className="footer-messages">
                        <div className="footer-messages-align-column">
                            <SendWhatsAppMessage />
                        </div>
                    </div>
                    <p>&copy; 2023 Develop Bit</p>
                </div>
            </footer>
        )
    );
}

export default Footer;
