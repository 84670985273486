import React, { useState } from 'react';
import DashboardChangeNameAndFunction from './DashboardChangeNameAndFunction';
import './DashboardKlanten.css';
import DashboardTelefoonBoekCompleet from "./DashboardTelefoonBoekCompleet";
import DashboardChangeKlantName from "./DashboardChangeKlantName";
import DashboardDeleteKlant from "./DashboardDeleteKlant";
import {IoArrowBackSharp} from "react-icons/io5";
import BackButton from "../../BackButton";

function DashboardKlanten({onBack}) {
    // State to manage the current view
    const [currentView, setCurrentView] = useState('home');

    // Function to reset to home view
    const handleBackToHome = () => {
        setCurrentView('home');
    };

    const renderCurrentView = () => {
        switch (currentView) {
            case 'changeKlantName':
                return (
                    <DashboardChangeKlantName onBack={handleBackToHome} />
                );
            case 'deleteKlant':
                return (
                    <DashboardDeleteKlant onBack={handleBackToHome} />
                );
            default:
                return (
                    <div className="home-screen">
                        <BackButton onClick={onBack} />
                        <br/>
                        <h2>Beheer</h2>
                        <p>Selecteer een actie om uit te voeren:</p>
                        <div className="db-klanten-submenu-action-buttons">
                            <button
                                onClick={() => setCurrentView('changeKlantName')}
                                className="db-klanten-submenu-action-button"
                            >
                                Verander naam begraafplaats
                            </button>

                            <button
                                onClick={() => setCurrentView('deleteKlant')}
                                className="db-klanten-submenu-action-button"
                            >
                                Begraafplaats verwijderen
                            </button>

                        </div>
                    </div>
                );
        }
    };

    return (
        <div className="dashboard-contact-personen">
            <div className="klanten-view-container">
                {renderCurrentView()}
            </div>
        </div>
    );
}

export default DashboardKlanten;