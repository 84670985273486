import React, { useState } from 'react';
import axios from 'axios'; // Import Axios
import './DashboardOpdrachtgeverAanmaken.css';
import {IoArrowBackSharp} from "react-icons/io5";

function DashboardOpdrachtgeverAanmaken({onBack}) {
    const API_BASE_URL = process.env.REACT_APP_API_HOST;

    // Initial state for opdrachtgever
    const initialOpdrachtgeverState = {
        opdrachtgeverNaam: '',
        phoneNumber: '',
        email: '',
        address: '',
        zipcode: '',
        housenumber: '',
        city: ''
    };

    // State for opdrachtgever data
    const [opdrachtgever, setOpdrachtgever] = useState(initialOpdrachtgeverState);

    // Handler for changes in opdrachtgever data
    const handleChange = (e) => {
        setOpdrachtgever({ ...opdrachtgever, [e.target.name]: e.target.value });
    };

    // Handler for form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${API_BASE_URL}/opdrachtgever/create`, opdrachtgever);
            console.log('Opdrachtgever succesvol aangemaakt:', response.data);
            // Reset fields after success
            setOpdrachtgever(initialOpdrachtgeverState);
            // Add any success handling logic (e.g., redirect, notification, etc.)
        } catch (error) {
            console.error('Er is een fout opgetreden bij het aanmaken van de opdrachtgever:', error);
            // Add error handling logic (e.g., show error message to user)
        }
    };

    return (
        <div className="opdrachtgever-container">
            <button
                onClick={onBack}
                style={{
                    background: 'darkblue',
                    fontSize: '2.5rem',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    padding: '10px' // Optional for spacing
                }}>
                <IoArrowBackSharp style={{ fontSize: '2.5rem' }} />
                Terug
            </button>
            <br/>            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Naam Opdrachtgever</label>
                    <input
                        type="text"
                        name="opdrachtgeverNaam"
                        value={opdrachtgever.opdrachtgeverNaam}
                        onChange={handleChange}
                        placeholder="Naam van de uitvaartorganisatie"
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Telefoonnummer</label>
                    <input
                        type="text"
                        name="phoneNumber"
                        value={opdrachtgever.phoneNumber}
                        onChange={handleChange}
                        placeholder="Telefoonnummer"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={opdrachtgever.email}
                        onChange={handleChange}
                        placeholder="Vul de email van de klant in"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="address">Adres</label>
                    <input
                        type="text"
                        id="address"
                        name="address"
                        value={opdrachtgever.address}
                        onChange={handleChange}
                        placeholder="Vul het adres van de klant in"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="housenumber">Huisnummer</label>
                    <input
                        type="text"
                        id="housenumber"
                        name="housenumber"
                        value={opdrachtgever.housenumber}
                        onChange={handleChange}
                        placeholder="Vul het huisnummer van de klant in"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="zipcode">Postcode</label>
                    <input
                        type="text"
                        id="zipcode"
                        name="zipcode"
                        value={opdrachtgever.zipcode}
                        onChange={handleChange}
                        placeholder="Vul de postcode van de klant in"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="city">Plaats</label>
                    <input
                        type="text"
                        id="city"
                        name="city"
                        value={opdrachtgever.city}
                        onChange={handleChange}
                        placeholder="Vul de plaats van de klant in"
                        required
                    />
                </div>

                <button type="submit" className="submit-button">Opslaan</button>
            </form>
        </div>
    );
}

export default DashboardOpdrachtgeverAanmaken;
