import React, { useState } from 'react';
import axios from 'axios';

function CreateUser() {
    const API_BASE_URL = process.env.REACT_APP_API_HOST;

    // State to manage form inputs
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        zipcode: '',
        houseNumber: ''
    });

    const [errors, setErrors] = useState({}); // State to manage validation errors

    // Handle form input changes
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({}); // Clear previous errors

        try {
            // Send POST request to create user
            const response = await axios.post(`${API_BASE_URL}/users/create`, formData);
            alert('User created successfully!');
        } catch (error) {
            if (error.response && error.response.data) {
                setErrors(error.response.data); // Capture validation errors
            } else {
                console.error('Error creating user', error);
            }
        }
    };

    return (
        <div className="CreateUser">
            <h2>Create User</h2>
            <form onSubmit={handleSubmit}>
                {/* Email */}
                <div>
                    <label>Email:</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    {errors.email && <p className="error">{errors.email}</p>}
                </div>

                {/* Password */}
                <div>
                    <label>Password:</label>
                    <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                        minLength="8"
                    />
                    {errors.password && <p className="error">{errors.password}</p>}
                </div>

                {/* First Name */}
                <div>
                    <label>First Name:</label>
                    <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                    />
                    {errors.firstName && <p className="error">{errors.firstName}</p>}
                </div>

                {/* Last Name */}
                <div>
                    <label>Last Name:</label>
                    <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                    />
                    {errors.lastName && <p className="error">{errors.lastName}</p>}
                </div>

                {/* Phone Number */}
                <div>
                    <label>Phone Number:</label>
                    <input
                        type="text"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                    />
                </div>

                {/* Zipcode */}
                <div>
                    <label>Zipcode:</label>
                    <input
                        type="text"
                        name="zipcode"
                        value={formData.zipcode}
                        onChange={handleChange}
                    />
                </div>

                {/* House Number */}
                <div>
                    <label>House Number:</label>
                    <input
                        type="text"
                        name="houseNumber"
                        value={formData.houseNumber}
                        onChange={handleChange}
                    />
                </div>

                <button type="submit">Create User</button>
            </form>

            {/* Display validation errors */}
            {Object.keys(errors).length > 0 && (
                <div className="error-summary">
                    <h3>Validation Errors:</h3>
                    <ul>
                        {Object.entries(errors).map(([field, error]) => (
                            <li key={field}>{field}: {error}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

export default CreateUser;
