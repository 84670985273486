import React, { useState } from 'react';
import './Finance.css';
import { FaTasks, FaDollarSign, FaClipboardList, FaHome } from "react-icons/fa"; // Added FaTasks, FaDollarSign, FaClipboardList, and FaHome for new options
import { GiDeliveryDrone } from "react-icons/gi"; // Replaced GiTruck with GiDeliveryDrone for a more modern delivery look

import FinanceTakenBeheer from "./FinanceTakenBeheer";
import WerkBonnen from "./WerkBonnen";
import DashboardAssignDistanceAndTimeToKlant from "./DashboardAssignDistanceAndTimeToKlant";

function Finance() {
    const [currentView, setCurrentView] = useState('home');

    const handleBackToHome = () => {
        setCurrentView('home');
    };

    const renderCurrentView = () => {
        switch (currentView) {
            case 'Taakduur':
                return <FinanceTakenBeheer onBack={handleBackToHome} />;
            case 'Voorrijkosten':
                return <DashboardAssignDistanceAndTimeToKlant onBack={handleBackToHome} />;
            case 'Werkbonnen':
                return <WerkBonnen onBack={handleBackToHome} />;
            case 'Overzicht': // Added Overzicht view case
                return (
                    <div className="overview-screen">
                        <h2>Financieel Overzicht</h2>
                        {/* Overview content goes here */}
                        <button onClick={handleBackToHome}>Terug naar Home</button>
                    </div>
                );
            default:
                return (
                    <div className="home-screen">
                        <h2 className="welcome-message">Welkom,</h2>
                        <p className="action-prompt">Selecteer een actie om uit te voeren:</p>
                        <div className="finance-section-container-align-row">
                            <div className="finance-section">
                                <h3 className="finance-section-title">Finance</h3>
                                <ul>
                                    <li className="dashboard-finance-align-pictogram-text-column" onClick={() => setCurrentView('Taakduur')}>
                                        <FaTasks style={{ fontSize: '2rem' }} /> {/* Changed to FaTasks for task-related icon */}
                                        <p className="dashboard-finance-picto-text-fontsize">Taakduur</p>
                                    </li>
                                    <li className="dashboard-finance-align-pictogram-text-column" onClick={() => setCurrentView('Voorrijkosten')}>
                                        <GiDeliveryDrone style={{ fontSize: '2rem' }} /> {/* Replaced with GiDeliveryDrone */}
                                        <p className="dashboard-finance-picto-text-fontsize">Voorrijkosten</p>
                                    </li>
                                    <li className="dashboard-finance-align-pictogram-text-column" onClick={() => setCurrentView('Werkbonnen')}>
                                        <FaClipboardList style={{ fontSize: '2rem' }} /> {/* Changed to FaClipboardList for work orders */}
                                        <p className="dashboard-finance-picto-text-fontsize">Werkbonnen</p>
                                    </li>
                                    <li className="dashboard-finance-align-pictogram-text-column" onClick={() => setCurrentView('Overzicht')}>
                                        <FaDollarSign style={{ fontSize: '2rem' }} /> {/* Added new "Finance Overview" option */}
                                        <p className="dashboard-finance-picto-text-fontsize">Overzicht</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                );
        }
    };

    return (
        <div className="finance-dashboard">
            <div className="finance-view-container">
                {renderCurrentView()}
            </div>
        </div>
    );
}

export default Finance;
