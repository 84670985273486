import React from 'react';
import {IoArrowBackSharp} from "react-icons/io5";
import BackButton from "../../BackButton";

function DashboardMachines({onBack}) {
    return (
        <div className="DashboardMachines">
            <BackButton onClick={onBack} />
            <br/>
        </div>
    );
}

export default DashboardMachines;
