import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './FinanceTakenBeheer.css';
import {IoArrowBackSharp} from "react-icons/io5";

function FinanceTakenBeheer({ onBack }) {
    const [tasks, setTasks] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [editingTaskId, setEditingTaskId] = useState(null);
    const [newTaskDuration, setNewTaskDuration] = useState('');

    const API_BASE_URL = process.env.REACT_APP_API_HOST;
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchTasks = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await axios.get(`${API_BASE_URL}/taak/allavailable`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                if (response.status === 200) {
                    setTasks(response.data);
                } else {
                    setError('Failed to fetch tasks.');
                }
            } catch (err) {
                setError('An error occurred while fetching tasks.');
            } finally {
                setLoading(false);
            }
        };

        const fetchCustomers = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/klant/all`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                if (response.status === 200) {
                    setCustomers(response.data);
                } else {
                    setError('Failed to fetch customers.');
                }
            } catch (err) {
                setError('An error occurred while fetching customers.');
            }
        };

        fetchTasks();
        fetchCustomers();
    }, [API_BASE_URL, token]);

    const handleCustomerChange = (event) => {
        setSelectedCustomer(event.target.value);
    };

    const booleanToText = (value) => (value ? 'Ja' : 'Nee');




    const startEditingTask = (task) => {
        setEditingTaskId(task.uuid);
        setNewTaskDuration(task.taskDuration);
    };

    const cancelEditingTask = () => {
        setEditingTaskId(null);
        setNewTaskDuration('');
    };

    const saveTaskDuration = async (task) => {
        try {
            const updatedTask = {
                ...task,
                taskDuration: newTaskDuration
            };

            await axios.put(`${API_BASE_URL}/taak/update`, updatedTask, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            setTasks((prevTasks) =>
                prevTasks.map((t) => (t.uuid === task.uuid ? updatedTask : t))
            );
            setEditingTaskId(null);
        } catch (error) {
            setError('Failed to update task duration.');
        }
    };

    const filteredTasks = tasks.filter((taak) =>
        selectedCustomer === "" || taak.customerTaskList === selectedCustomer
    );

    return (
        <section className="tasks-section">
            <button
                onClick={onBack}
                style={{
                    background: 'darkblue',
                    fontSize: '2.5rem',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    padding: '10px' // Optional for spacing
                }}>
                <IoArrowBackSharp style={{ fontSize: '2.5rem' }} />
                Terug
            </button>
            <br/>
            <h3 className="section-title">Taakduur corrigeren</h3>

            {loading && <p className="loading-text">Loading...</p>}
            {error && <p className="error-text">{error}</p>}

            <div className="customer-dropdown">
                <label htmlFor="customer-select" className="dropdown-label">Selecteer Klant:</label>
                <select
                    id="customer-select"
                    onChange={handleCustomerChange}
                    value={selectedCustomer}
                    className="dropdown-select"
                >
                    <option value="">Selecteer een klant</option>
                    {customers.map((customer) => (
                        <option key={customer.uuid} value={customer.klantNaam}>
                            {customer.klantNaam}
                        </option>
                    ))}
                </select>
            </div>

            {!loading && !error && (
                <table className="tasks-table">
                    <thead>
                    <tr className="table-header-row">
                        <th className="table-header">Klantnaam</th>
                        <th className="table-header">Taakgroep</th>
                        <th className="table-header">Taaknaam</th>
                        <th className="table-header">Taakduur</th>
                        <th className="table-header">Actie</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredTasks.length > 0 ? (
                        filteredTasks.map((taak) => (
                            <tr key={taak.uuid} className="table-row">
                                <td className="table-cell">{taak.customerTaskList}</td>
                                <td className="table-cell">{taak.taskGroup}</td>
                                <td className="table-cell">{taak.taskName}</td>
                                <td className="table-cell">
                                    {editingTaskId === taak.uuid ? (
                                        <input
                                            type="number"
                                            className="input-field"
                                            value={newTaskDuration}
                                            onChange={(e) => setNewTaskDuration(e.target.value)}
                                        />
                                    ) : (
                                        `${taak.taskDuration} mins`
                                    )}
                                </td>
                                <td className="table-cell action-buttons">
                                    {editingTaskId === taak.uuid ? (
                                        <>
                                            <button
                                                onClick={() => saveTaskDuration(taak)}
                                                className="save-button"
                                            >
                                                Opslaan
                                            </button>
                                            <button
                                                onClick={cancelEditingTask}
                                                className="cancel-button"
                                            >
                                                Annuleren
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button
                                                onClick={() => startEditingTask(taak)}
                                                className="edit-button"
                                            >
                                                Bewerken
                                            </button>
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6" className="no-tasks-message">Geen taken beschikbaar</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            )}
        </section>
    );
}

export default FinanceTakenBeheer;
