import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { IoArrowBackSharp } from "react-icons/io5";
import './DashboardAssignDistanceAndTimeToKlant.css';
import Voorrijkosten from './Voorrijkosten';
import {AiOutlineEdit} from "react-icons/ai";
import BackButton from "../BackButton";

function DashboardAssignDistanceAndTimeToKlant({onBack}) {
    const API_BASE_URL = process.env.REACT_APP_API_HOST;
    const [klanten, setKlanten] = useState([]);
    const [reistijdVanafZaak, setReistijdVanafZaak] = useState('');
    const [afstandVanafZaak, setAfstandVanafZaak] = useState('');
    const [editingKlantId, setEditingKlantId] = useState(null);
    const [voorrijkosten, setVoorrijkosten] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);


    // Fetch all klanten and voorrijkosten on component load
    useEffect(() => {
        // Fetch klanten data
        axios.get(`${API_BASE_URL}/klant/all`)
            .then(response => setKlanten(response.data))
            .catch(error => console.error('Error fetching klanten:', error));

        // Fetch voorrijkosten values
        axios.get(`${API_BASE_URL}/finance/all`)
            .then(response => {
                if (response.data.length > 0) {
                    setVoorrijkosten({
                        upToTwentyFiveKm: response.data[0].upToTwentyFiveKm,
                        upToFourtyKm: response.data[0].upToFourtyKm,
                        upToSixtyKm: response.data[0].upToSixtyKm,
                    });
                }
            })
            .catch(error => console.error('Error fetching voorrijkosten:', error));
    }, [API_BASE_URL]);

    // Function to determine travel fee based on distance
    const getFeeByDistance = (distance) => {
        if (distance < 25) {
            return voorrijkosten.upToTwentyFiveKm ? `${voorrijkosten.upToTwentyFiveKm} EUR` : "Onbekend";
        } else if (distance >= 25 && distance < 40) {
            return voorrijkosten.upToFourtyKm ? `${voorrijkosten.upToFourtyKm} EUR` : "Onbekend";
        } else if (distance >= 40 && distance < 60) {
            return voorrijkosten.upToSixtyKm ? `${voorrijkosten.upToSixtyKm} EUR` : "Onbekend";
        }
        return "Buiten bereik";  // Default for distances above 60
    };

    // Update distance and travel time for the selected customer
    const updateKlantReistijdAfstand = (klantId) => {
        const token = localStorage.getItem('token');

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };

        const klant = klanten.find(k => k.uuid === klantId);

        if (!klant) {
            console.error('Klant niet gevonden!');
            return;
        }

        const updatedKlant = {
            ...klant,
            reistijdVanafZaak: reistijdVanafZaak,
            afstandVanafZaak: afstandVanafZaak
        };

        axios.put(`${API_BASE_URL}/klant/update`, updatedKlant, {headers})
            .then(response => {
                alert('Reistijd en afstand succesvol bijgewerkt!');
                setEditingKlantId(null);  // Close editing mode
            })
            .catch(error => {
                console.error('Error updating klant data:', error);
            });
    };

    return (
        <div className="dashboard-assign-distance-time">
            <BackButton onClick={onBack} />
            <br/>

            <button className="voorrijkosten-button" onClick={() => setIsModalOpen(true)}>
                Voorrijkosten wijzigen
            </button>

            <table className="klant-table">
                <thead>
                <tr>
                    <th className="table-header">Klant Naam</th>
                    <th className="table-header">Reistijd</th>
                    <th className="table-header">Afstand</th>
                    <th className="table-header">Voorrijkosten</th>
                    <th className="table-header">Acties</th>
                </tr>
                </thead>
                <tbody>
                {klanten.map((klant) => (
                    <tr key={klant.uuid} className="table-row">
                        <td className="table-cell">{klant.klantNaam}</td>

                        {editingKlantId === klant.uuid ? (
                            <>
                                <td className="table-cell">
                                    <input
                                        className="input-field"
                                        type="number"
                                        value={reistijdVanafZaak}
                                        onChange={(e) => setReistijdVanafZaak(e.target.value)}
                                        placeholder="Reistijd in minuten"
                                    />
                                </td>
                                <td className="table-cell">
                                    <input
                                        className="input-field"
                                        type="number"
                                        value={afstandVanafZaak}
                                        onChange={(e) => setAfstandVanafZaak(e.target.value)}
                                        placeholder="Afstand in km"
                                    />
                                </td>

                                <td className="table-cell">{getFeeByDistance(Number(afstandVanafZaak))}</td>
                                <td className="table-cell">
                                    <button className="action-button save-button"
                                            onClick={() => updateKlantReistijdAfstand(klant.uuid)}>
                                        Opslaan
                                    </button>
                                    <button className="action-button cancel-button"
                                            onClick={() => setEditingKlantId(null)}>
                                        Annuleren
                                    </button>
                                </td>
                            </>
                        ) : (
                            <>
                                <td className="table-cell">
                                    {klant.reistijdVanafZaak ? `${klant.reistijdVanafZaak} min` : 'Onbekend'}
                                </td>
                                <td className="table-cell">
                                    {klant.afstandVanafZaak ? `${klant.afstandVanafZaak} km` : 'Onbekend'}
                                </td>

                                <td className="table-cell">{getFeeByDistance(Number(klant.afstandVanafZaak))}</td>
                                <td className="table-cell">
                                    <button className="action-button edit-button" onClick={() => {
                                        setEditingKlantId(klant.uuid);
                                        setAfstandVanafZaak(klant.afstandVanafZaak || '');
                                        setReistijdVanafZaak(klant.reistijdVanafZaak || '');
                                    }}>
                                        <AiOutlineEdit style={{fontSize: '1.7rem'}}/>
                                    </button>
                                </td>
                            </>
                        )}
                    </tr>
                ))}
                </tbody>
            </table>
            {/* Modal for Voorrijkosten */}
            {isModalOpen && (
                <Voorrijkosten voorrijkosten={voorrijkosten} onClose={() => setIsModalOpen(false)} />
            )}
        </div>
    );
}
export default DashboardAssignDistanceAndTimeToKlant;
