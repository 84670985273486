import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './DashboardTelefoonBoekCompleet.css';
import {IoArrowBackSharp} from "react-icons/io5";

function DashboardTelefoonBoekCompleet({ onBack }) {
    const API_BASE_URL = process.env.REACT_APP_API_HOST; // Use your backend API base URL
    const [contactPersonen, setContactPersonen] = useState([]); // State to hold contact persons
    const [error, setError] = useState(null); // State to hold error messages
    const [editingContact, setEditingContact] = useState(null); // State for the contact being edited
    const [updatedContact, setUpdatedContact] = useState({ // State to hold the updated contact details
        contactPersoonNaam: '',
        phoneNumber: '',
        email: '',
        functieTitel: '',
        assignedKlant: '', // Add assignedKlant field
    });

    const [filter, setFilter] = useState(''); // State for the selected filter

    const assignedKlantOptions = [
        'Eikelenburg',
        'S.W.C.',
        'Nieuw Eijkenduijnen',
        'Hendrik-Ido-Ambacht',
        'Vlaardingen Holy',
        'Alblasserdam',
        'Kethel Kerkweg',
        'Kethel Noordeinde',
        'Maassluis- Oud',
        "'t Woudt",
        'Joodse begraafplaats',
        'J.B. Laan',
        'Berkel en Roderijs',
        'Zoetermeer RK',
        'Kijfhoek',
        'Ommering',
        'Krimpenerwaard',
        'De Lier',
        'Wateringen',
        'Monster',
        'Puttershoek',
        "'s-Gravendeel",
        'Numansdorp',
        'Klaaswaal',
        'Westeinde',
        'Protestantse begraafplaats',
    ];

    // Fetch all contact persons when the component mounts
    useEffect(() => {
        const fetchContactPersonen = async () => {
            try {
                const token = localStorage.getItem('token'); // Retrieve the token from localStorage
                const response = await axios.get(`${API_BASE_URL}/contactpersoon/all`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                    },
                });
                setContactPersonen(response.data); // Set the fetched contact persons
            } catch (error) {
                console.error('Error fetching contact persons:', error);
                setError('Failed to fetch contact persons.'); // Set error message
            }
        };

        fetchContactPersonen(); // Call the function to fetch data
    }, [API_BASE_URL]); // Dependency array to run effect only once on mount

    // Handle edit button click
    const handleEditClick = (persoon) => {
        setEditingContact(persoon); // Set the contact being edited
        setUpdatedContact({ // Pre-fill the form with the current values
            contactPersoonNaam: persoon.contactPersoonNaam,
            phoneNumber: persoon.phoneNumber,
            email: persoon.email,
            functieTitel: persoon.functieTitel,
            assignedKlant: persoon.assignedKlant, // Pre-fill assignedKlant
        });
    };

    // Handle input changes in the update form
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUpdatedContact(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    // Handle form submission to update the contact
    const handleUpdateSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission
        try {
            const token = localStorage.getItem('token'); // Retrieve the token from localStorage
            await axios.put(`${API_BASE_URL}/contactpersoon/update`, {
                uuid: editingContact.uuid, // Include the UUID of the contact being edited
                ...updatedContact, // Spread the updated contact details
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                },
            });
            // Update the contact list after successful edit
            setContactPersonen(prevContacts =>
                prevContacts.map(contact => (contact.uuid === editingContact.uuid ? { ...contact, ...updatedContact } : contact))
            );
            setEditingContact(null); // Reset editing state
        } catch (error) {
            console.error('Error updating contact:', error);
            setError('Failed to update contact.'); // Set error message
        }
    };

    // Handle delete button click
    const handleDeleteClick = async (uuid) => {
        try {
            const token = localStorage.getItem('token'); // Retrieve the token from localStorage
            await axios.delete(`${API_BASE_URL}/contactpersoon/${uuid}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                },
            });
            // Update the contact list after successful delete
            setContactPersonen(prevContacts => prevContacts.filter(contact => contact.uuid !== uuid));
        } catch (error) {
            console.error('Error deleting contact:', error);
            setError('Failed to delete contact.'); // Set error message
        }
    };

    // Handle filter change
    const handleFilterChange = (e) => {
        setFilter(e.target.value); // Update the filter state
    };

    // Filtered contacts based on assignedKlant
    const filteredContactPersonen = contactPersonen.filter(persoon =>
        persoon.assignedKlant.includes(filter)
    );

    return (
        <div className="DashboardTelefoonBoekCompleet">
            <button onClick={onBack}><IoArrowBackSharp style={{ fontSize: '18px' }} /></button>
            <h1>Contactpersonen</h1>
            {error && <p className="error">{error}</p>} {/* Display error message if any */}

            {/* Filter Dropdown */}
            <div className="filter-container">
                <label htmlFor="filter">Filter op Assigned Klant: </label>
                <select id="filter" value={filter} onChange={handleFilterChange}>
                    <option value="">Alle</option>
                    {assignedKlantOptions.map(option => (
                        <option key={option} value={option}>{option}</option>
                    ))}
                </select>
            </div>

            {/* Edit Form for Updating Contact Details */}
            {editingContact && (
                <form className="edit-form" onSubmit={handleUpdateSubmit}>
                    <h2>Update Contact</h2>
                    <input
                        type="text"
                        name="contactPersoonNaam"
                        placeholder="Naam"
                        value={updatedContact.contactPersoonNaam}
                        onChange={handleChange}
                        required
                    />
                    <input
                        type="text"
                        name="phoneNumber"
                        placeholder="Telefoonnummer"
                        value={updatedContact.phoneNumber}
                        onChange={handleChange}
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={updatedContact.email}
                        onChange={handleChange}
                    />
                    <input
                        type="text"
                        name="functieTitel"
                        placeholder="Functie Titel"
                        value={updatedContact.functieTitel}
                        onChange={handleChange}
                        required
                    />
                    <input
                        type="text"
                        name="assignedKlant"
                        placeholder="Assigned Klant" // Placeholder for assignedKlant
                        value={updatedContact.assignedKlant}
                        onChange={handleChange}
                        required
                    />
                    <button type="submit">Update Contact</button>
                    <button type="button" onClick={() => setEditingContact(null)}>Cancel</button> {/* Cancel button */}
                </form>
            )}

            {/* List of Contacts */}
            <ul className="contact-list">
                {filteredContactPersonen.map((persoon) => (
                    <li key={persoon.uuid} className="contact-item">
                        <span className="contact-details">
                            {persoon.contactPersoonNaam} - {persoon.phoneNumber} - {persoon.email} - {persoon.functieTitel} - {persoon.assignedKlant}
                        </span>
                        <div className="contact-actions">
                            <button className="edit-button" onClick={() => handleEditClick(persoon)}>Edit</button> {/* Edit button */}
                            <button className="delete-button" onClick={() => handleDeleteClick(persoon.uuid)}>Delete</button> {/* Delete button */}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default DashboardTelefoonBoekCompleet;
