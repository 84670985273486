import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import './Login.css'; // Import your CSS file
import { AuthContext } from '../context/AuthContext';
import weverlingwijtmanlogologin from "../assets/weverlingwijtmanlogologin.png";
import {AiOutlineLogin} from "react-icons/ai";
import {FaKey} from "react-icons/fa";
import LoginSuccessful from "./LoginSuccessful";

//TODO Add login options for using Google, Facebook, LinkedIn


function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, toggleError] = useState(false);
    const { login, isAuth } = useContext(AuthContext);
    const navigate = useNavigate();
    const [showLoginSuccess, setShowLoginSuccess] = useState(false);

    useEffect(() => {
        if (isAuth) {
            setShowLoginSuccess(true); // Show the LoginSuccessful component

            const timer = setTimeout(() => {
                setShowLoginSuccess(false); // Hide the LoginSuccessful component
                navigate('/dashboard'); // Redirect to /dashboard after 2 seconds
            }, 2000);

            return () => clearTimeout(timer); // Clear timeout if component unmounts
        }
    }, [isAuth, navigate]);

    async function handleSubmit(e) {
        e.preventDefault();
        toggleError(false);

        try {
            const result = await axios.post(`${process.env.REACT_APP_API_HOST}/authenticate`, {
                username: username,
                password: password,
            });

            login(result.data.jwt);
            // const savedRequest = localStorage.getItem("savedRequest");
            // if(savedRequest !== null){
            //     navigate('/dashboard');
            //     return;
            // }
            const timer = setTimeout(() => {
                setShowLoginSuccess(false); // Hide the LoginSuccessful component
                navigate('/dashboard'); // Redirect to /dashboard after 2 seconds
            }, 2000);

            return () => clearTimeout(timer); // Clear timeout if component unmounts
        } catch (e) {
            toggleError(true);
        }
    }

    return (
        <div className="login-container">
            {showLoginSuccess ? (
                <LoginSuccessful />
            ) : (
                <div className="login-section">
                    <form onSubmit={handleSubmit} className="login-form">
                        {/*<img src={weverlingwijtmanlogologin} alt="Software Begraafplaatswerken Logo" className="login-logo" />*/}
                        <label className="login-label">
                            <div className="align-login-text-row"><FaKey /> Log-in met je account</div>

                            <input
                                type="email"
                                id="email-field"
                                name="email"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                className="login-input"
                                placeholder="Gebruikersnaam"
                            />
                            <input
                                type="password"
                                id="password-field"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="login-input"
                                placeholder="Wachtwoord"
                            />
                        </label>
                        <br/>
                        {error && (<p className="login-error">Combinatie van emailadres en wachtwoord is onjuist</p>)}
                        <button type="submit" className="login-button">{/*<AiOutlineLogin style={{ fontSize: '1.8rem' }}/>*/} Inloggen</button>
                    </form>
                <br/>
                <br/>
                <a
                    href="https://wa.me/31649226997"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="whatsapp-link"
                >
                    App beheerder
                </a>

            </div>
            )}
        </div>
    );
}

export default Login;



{/*
            {isAuth ? (
                <p>You are already logged in. Redirecting to /request...</p>
            ) : (
*/}