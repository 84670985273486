import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './Dashboard.css';
import DashboardVoertuigen from '../Dashboard/Operator/Beheren/DashboardVoertuigen';
import DashboardSettings from './DashboardSettings';
import DashboardWijtmanPlanningMaken from "./Planner/DashboardWijtmanPlanningMaken";
import DashboardWijtmanFillablePdf from "./DashboardWijtmanFillablePdf";
import DashboardWijtmanPersoneelAanmaken from "../Dashboard/Operator/Aanmaken/DashboardWijtmanPersoneelAanmaken.css";
import DashboardWijtmanVoertuigAanmaken from "../Dashboard/Operator/Aanmaken/DashboardWijtmanVoertuigAanmaken";
import DashboardWijtmanMachineAanmaken from "../Dashboard/Operator/Aanmaken/DashboardWijtmanMachineAanmaken";
import DashboardHome from "./DashboardHome";
import DashboardWijtmanKlantAanmaken from "../Dashboard/Operator/Aanmaken/DashboardWijtmanKlantAanmaken";
import DashboardPersoneelsleden from "../Dashboard/Operator/Beheren/DashboardPersoneelsleden";
import DashboardMachines from "../Dashboard/Operator/Beheren/DashboardMachines";
import DashboardKlanten from "../Dashboard/Operator/Beheren/DashboardKlanten";
import DashboardOpdrachtgeverAanmaken from "../Dashboard/Operator/Aanmaken/DashboardOpdrachtgeverAanmaken";
import DashboardContactPersoonAanmaken from "../Dashboard/Operator/Aanmaken/DashboardContactPersoonAanmaken";
import DashboardOpdrachtgevers from "../Dashboard/Operator/Beheren/DashboardOpdrachtgevers";
import DashboardContactPersonen from "../Dashboard/Operator/Beheren/DashboardContactPersonen";
import DashboardPhoneBook from "./DashboardPhoneBook";
import { RiContactsBook3Fill } from "react-icons/ri";
import DashboardChangeNameAndFunction from "../Dashboard/Operator/Beheren/DashboardChangeNameAndFunction";
import DashboardTaken from "../Dashboard/Operator/Beheren/DashboardTaken";
import DashboardTaakAanmaken from "../Dashboard/Operator/Aanmaken/DashboardTaakAanmaken";
import UserManagement from "../UserManagement";
import PlanTaakList from "./Planner/PlantakenOverzicht";
import { AuthContext } from "../../context/AuthContext";
import { jwtDecode } from 'jwt-decode';
import Voorrijkosten from "./Finance/Voorrijkosten";
import CreateUser from "../CreateUser";
import DashboardPlanningBuitendienst from "./DashboardPlanningBuitendienst";
import {IoHomeOutline} from "react-icons/io5";
import {IoMdClose, IoMdMenu} from "react-icons/io";
import {AiOutlineDatabase, AiOutlineExclamationCircle, AiOutlineLogout, AiOutlineMenu} from "react-icons/ai";
import {LuClipboardList} from "react-icons/lu";
import weverlingwijtmandashboardmenucontrast from "../../assets/weverlingwijtmandashboardmenucontrast.png";
import WerkBonnen from "./Finance/WerkBonnen";
import DashboardAssignDistanceAndTimeToKlant from "./Finance/DashboardAssignDistanceAndTimeToKlant";
import FinanceTakenBeheer from "./Finance/FinanceTakenBeheer";
import VoorwerpMelden from "./VoorwerpMelden";
import Operator from "./Operator/Operator";
import Finance from "./Finance/Finance";
import {FaChartLine, FaMoneyBillWave, FaRegCalendarAlt} from "react-icons/fa";
import Planner from "./Planner/Planner";
import Logout from "../Logout";

function Dashboard() {
    const {isAuth, isNotAuth} = useContext(AuthContext);
    const [currentView, setCurrentView] = useState('Home');
    const [showDigitalForms, setShowDigitalForms] = useState(false);
    const [showUserMenu, setShowUserMenu] = useState(false);

    const [showFinance, setShowFinance] = useState(false);
    const [showPlannen, setShowPlannen] = useState(false);
    const [showAanmaken, setShowAanmaken] = useState(false);
    const [showBeheren, setShowBeheren] = useState(false);
    const [showOperator, setShowOperator] = useState(false); // State for operator section
    const [showAdmin, setShowAdmin] = useState(false); // State for admin section
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [roles, setRoles] = useState([]);
    const location = useLocation();

    useEffect(() => {
        console.log('Authentication Status:', isAuth);

        if (!isAuth) {
            return;
        }

        const token = localStorage.getItem('token');
        if (!token) {
            console.warn('No token found');
            return;
        }

        try {
            const decoded = jwtDecode(token);
            console.log('Decoded Token:', decoded);
            setRoles(decoded.roles || []);
        } catch (error) {
            console.error('Error decoding token:', error);
        }
    }, [isAuth]);

    const handleViewChange = (view) => {
        setCurrentView(view);

        // Check screen width to determine if sidebar should remain open
        if (window.innerWidth < 1024) {
            setSidebarOpen(false); // Close sidebar on smaller screens
        } else {
            setSidebarOpen(true); // Keep sidebar open on desktop
        }
    };

// Optional: Add an event listener to handle screen resizing
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1024) {
                setSidebarOpen(true);
            }
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const goToPlanlijst = () => {
        setCurrentView('Planlijst');

        // Check if the screen width is less than 1024px (desktop breakpoint)
        if (window.innerWidth < 1024) {
            setSidebarOpen(false); // Close sidebar on smaller screens
        } else {
            setSidebarOpen(true); // Keep sidebar open on desktop screens
        }
    };

// Optional: Add an event listener to handle dynamic resizing
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1024) {
                setSidebarOpen(true); // Ensure sidebar stays open on larger screens
            }
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize); // Cleanup on unmount
    }, []);


    const toggleSidebar = () => {
        setSidebarOpen(prev => !prev);
    };

    const isDashboardRoute = location.pathname === '/dashboard';
    const isAdmin = roles.includes('ADMIN');
    const isPlanner = roles.includes('PLANNER');
    const isFinance = roles.includes('FINANCE');
    const isOperator = roles.includes('OPERATOR');
    const isUser = roles.includes('USER');

    return (
        <div className={`dashboard-container ${isDashboardRoute ? 'dashboard-slide-up' : ''}`}>
            <aside className={`dashboard-sidebar ${sidebarOpen ? 'open' : 'closed'}`}>
                <nav>
                    <ul>
                        <ul className="dashboard-sidebar-big-nav-group">
                        <li
                            className="dashboard-sidebar-big-nav-group-align-column"
                            onClick={() => handleViewChange('Home')}>
                            <IoHomeOutline
                                style={{ fontSize: '2rem' }}  />
                            Home
                        </li>
                        {isAuth && (
                            <li
                                className="dashboard-sidebar-big-nav-group-align-column"
                                onClick={isNotAuth}>
                                <AiOutlineLogout
                                    style={{ fontSize: '2rem' }}/>
                                    Log uit
                            </li>
                        )}
                        </ul>
                        {/*//todo Move isAdmin to a seperate frontend*/}
                        {/*{isAdmin && ( // Show admin-specific menu only for admins*/}
                        {/*    <>*/}
                        {/*        <h4*/}
                        {/*            className="dashboard-sidebar-section-header"*/}
                        {/*            onClick={() => setShowAdmin(prev => !prev)} // Allow expanding/collapsing*/}
                        {/*            style={{ cursor: 'pointer' }}*/}
                        {/*        >*/}
                        {/*            Admin {showAdmin ? '▲' : '▼'}*/}
                        {/*        </h4>*/}
                        {/*        {showAdmin && ( // Render admin content if expanded*/}
                        {/*            <>*/}
                        {/*                <li onClick={() => handleViewChange('Gebruiker')}>Gebruiker</li>*/}
                        {/*                <li onClick={() => handleViewChange('Gebruikers')}>Gebruikers</li>*/}
                        {/*            </>*/}
                        {/*        )}*/}
                        {/*    </>*/}
                        {/*)}*/}





                        {/*{(isAdmin || isOperator) && (*/}
                        {/*    <>*/}
                        {/*        <h4*/}
                        {/*            className="dashboard-sidebar-section-header"*/}
                        {/*            onClick={() => setShowOperator(prev => !prev)} // Allow expanding/collapsing*/}
                        {/*            style={{ cursor: 'pointer' }}*/}
                        {/*        >*/}
                        {/*            Operator {showOperator ? '▲' : '▼'}*/}
                        {/*        </h4>*/}
                        {/*        {showOperator && ( // Render admin content if expanded*/}
                        {/*            <>*/}

                        {/*                <h5*/}
                        {/*                    className="dashboard-sidebar-section-header"*/}
                        {/*                    onClick={() => setShowAanmaken(prev => !prev)}*/}
                        {/*                    style={{ cursor: 'pointer' }}*/}
                        {/*                >*/}
                        {/*                    Aanmaken {showAanmaken ? '▲' : '▼'}*/}
                        {/*                </h5>*/}
                        {/*                {showAanmaken && (*/}
                        {/*                    <>*/}
                        {/*                        <li onClick={() => handleViewChange('Personeel')}>Personeel</li>*/}
                        {/*                        <li onClick={() => handleViewChange('Voertuig')}>Voertuig</li>*/}
                        {/*                        <li onClick={() => handleViewChange('Machine')}>Machine</li>*/}
                        {/*                        <li onClick={() => handleViewChange('Taak')}>Taak</li>*/}
                        {/*                        <li onClick={() => handleViewChange('Opdrachtgever')}>Opdrachtgever</li>*/}
                        {/*                        <li onClick={() => handleViewChange('Klant')}>Klant</li>*/}
                        {/*                        <li onClick={() => handleViewChange('Contactpersoon')}>Contactpersoon</li>*/}
                        {/*                    </>*/}
                        {/*                )}*/}

                        {/*                <h5*/}
                        {/*                    className="dashboard-sidebar-section-header"*/}
                        {/*                    onClick={() => setShowBeheren(prev => !prev)}*/}
                        {/*                    style={{ cursor: 'pointer' }}*/}
                        {/*                >*/}
                        {/*                    Beheren {showBeheren ? '▲' : '▼'}*/}
                        {/*                </h5>*/}
                        {/*                {showBeheren && (*/}
                        {/*                    <>*/}
                        {/*                        <li onClick={() => handleViewChange('Personeelsleden')}>Personeelsleden</li>*/}
                        {/*                        <li onClick={() => handleViewChange('Voertuigen')}>Voertuigen</li>*/}
                        {/*                        <li onClick={() => handleViewChange('Machines')}>Machines</li>*/}
                        {/*                        <li onClick={() => handleViewChange('Taken')}>Taken</li>*/}
                        {/*                        <li onClick={() => handleViewChange('Opdrachtgevers')}>Opdrachtgevers</li>*/}
                        {/*                        <li onClick={() => handleViewChange('Klanten')}>Klanten</li>*/}
                        {/*                        <li onClick={() => handleViewChange('Contactpersonen')}>Contactpersonen</li>*/}
                        {/*                    </>*/}
                        {/*                )}*/}
                        {/*                <br/>*/}
                        {/*            </>*/}
                        {/*        )}*/}
                        {/*    </>*/}
                        {/*)}*/}


                        {/*{(isAdmin || isFinance) && (*/}
                        {/*    <>*/}
                        {/*        <>*/}
                        {/*            <h5*/}
                        {/*                className="dashboard-sidebar-section-header"*/}
                        {/*                onClick={() => setShowFinance(prev => !prev)}*/}
                        {/*                style={{ cursor: 'pointer' }}*/}
                        {/*            >*/}
                        {/*                Financiën {showFinance ? '▲' : '▼'}*/}
                        {/*            </h5>*/}
                        {/*            {showFinance && (*/}
                        {/*                <>*/}
                        {/*                    <li onClick={() => handleViewChange('Voorrijkosten')}>Voorrijkosten</li>*/}
                        {/*                    <li onClick={() => handleViewChange('Taakduur')}>Taakduur</li>*/}

                        {/*                    <li onClick={() => handleViewChange('Werkbon')}>Werkbon</li>*/}
                        {/*                </>*/}
                        {/*                )}*/}

                        {/*        </>*/}
                        {/*    </>*/}
                        {/*)}*/}




                        {/*<h5*/}
                        {/*    className="dashboard-sidebar-section-header"*/}
                        {/*    onClick={() => setShowDigitalForms(prev => !prev)}*/}
                        {/*    style={{ cursor: 'pointer' }}*/}
                        {/*>*/}
                        {/*    Formulieren {showDigitalForms ? '▲' : '▼'}*/}
                        {/*</h5>*/}
                        {/*{showDigitalForms && (*/}
                        {/*    <>*/}
                        {/*        <li onClick={() => handleViewChange('Aanmeldformulier')}>Aanmeldformulier</li>*/}
                        {/*    </>*/}
                        {/*)}*/}


                        <br />
<br/><br/>
                        <ul className="dashboard-sidebar-align-pictogram-row">
                            <li className="dashboard-sidebar-align-pictogram-text-column" onClick={() => handleViewChange('Buitendienst')}>
                                <LuClipboardList style={{ fontSize: '2rem' }} />
                                <p className="dashboard-sidebar-picto-text-fontsize">Planning</p>
                            </li>
                            <li className="dashboard-sidebar-align-pictogram-text-column" onClick={() => handleViewChange('Telefoonboek')}>
                            <RiContactsBook3Fill style={{ fontSize: '2rem' }} />
                            <p className="dashboard-sidebar-picto-text-fontsize">Contacten</p>
                        </li>
                            <li className="dashboard-sidebar-align-pictogram-text-column" onClick={() => handleViewChange('Melden')}>
                                <AiOutlineExclamationCircle style={{ fontSize: '2rem' }} />
                                <p className="dashboard-sidebar-picto-text-fontsize">Melden</p>
                            </li>
                            {(isAdmin || isOperator || isPlanner) && (
                                <li className="dashboard-sidebar-align-pictogram-text-column" onClick={() => handleViewChange('Planner')}>
                                <FaRegCalendarAlt style={{ fontSize: '2rem' }} />
                                <p className="dashboard-sidebar-picto-text-fontsize">Planner</p>
                            </li>
                            )}
                            {(isAdmin || isOperator || isFinance) && (

                                <li className="dashboard-sidebar-align-pictogram-text-column" onClick={() => handleViewChange('Finance')}>
                                <FaChartLine style={{ fontSize: '2rem' }} />
                                <p className="dashboard-sidebar-picto-text-fontsize">CFO</p>
                            </li>
                                )}
                            {(isAdmin || isOperator) && (
                                <li className="dashboard-sidebar-align-pictogram-text-column" onClick={() => handleViewChange('Operator')}>
                                <AiOutlineDatabase style={{ fontSize: '2rem' }} />
                                <p className="dashboard-sidebar-picto-text-fontsize">Database</p>
                            </li>
                                )}
                        </ul>

                    </ul>
                </nav>
                <img src={weverlingwijtmandashboardmenucontrast} alt="Software Begraafplaatswerken Logo" className="dashboard-sidebar-logo" />

            </aside>
            <main className="dashboard-main">
                <header className="dashboard-header">
                    <div className="dashboard-controls">
                        <button onClick={toggleSidebar} className="db-toggle-sidebar-button">
                            {sidebarOpen ? <IoMdClose style={{ fontSize: '1.5rem' }} /> : <AiOutlineMenu style={{ fontSize: '1.5rem' }} />}
                        </button>
                    </div>
                </header>

                {currentView === 'Home' && <DashboardHome />}
                {currentView === 'PlantaakMaken' && <DashboardWijtmanPlanningMaken goToPlanlijst={goToPlanlijst} />}
                {currentView === 'Machine' && <DashboardWijtmanMachineAanmaken />}
                {currentView === 'Voertuig' && <DashboardWijtmanVoertuigAanmaken />}
                {currentView === 'Personeel' && <DashboardWijtmanPersoneelAanmaken />}
                {currentView === 'Klant' && <DashboardWijtmanKlantAanmaken />}
                {currentView === 'Contactpersoon' && <DashboardContactPersoonAanmaken />}
                {currentView === 'Taak' && <DashboardTaakAanmaken />}
                {currentView === 'Opdrachtgever' && <DashboardOpdrachtgeverAanmaken />}
                {currentView === 'Voertuigen' && <DashboardVoertuigen />}
                {currentView === 'Machines' && <DashboardMachines />}
                {currentView === 'Personeelsleden' && <DashboardPersoneelsleden />}
                {currentView === 'Klanten' && <DashboardKlanten />}
                {currentView === 'Contactpersonen' && <DashboardContactPersonen />}
                {currentView === 'Taken' && <DashboardTaken />}
                {currentView === 'ChangeName' && <DashboardChangeNameAndFunction />}
                {currentView === 'Opdrachtgevers' && <DashboardOpdrachtgevers />}
                {currentView === 'Telefoonboek' && <DashboardPhoneBook />}
                {currentView === 'Melden' && <VoorwerpMelden />}

                {currentView === 'Aanmeldformulier' && <DashboardWijtmanFillablePdf />}
                {currentView === 'Settings' && <DashboardSettings />}
                {currentView === 'Gebruiker' && <CreateUser />}
                {currentView === 'Gebruikers' && <UserManagement />}
                {currentView === 'Planlijst' && <PlanTaakList />}
                {currentView === 'Voorrijkosten' && <DashboardAssignDistanceAndTimeToKlant />}
                {currentView === 'Taakduur' && <FinanceTakenBeheer />}
                {currentView === 'Planner' && <Planner />}

                {currentView === 'Operator' && <Operator />}
                {currentView === 'Finance' && <Finance />}


                {currentView === 'Werkbon' && <WerkBonnen />}

                {currentView === 'Buitendienst' && <DashboardPlanningBuitendienst />}


            </main>
        </div>
    );
}

export default Dashboard;
