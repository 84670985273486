import React from 'react';
import BackButton from "../../BackButton";

function DashboardOpdrachtgevers({onBack}) {
    return (
        <div className="DashboardOpdrachtgevers">
            <BackButton onClick={onBack} />
            <br/>
        </div>
    );
}

export default DashboardOpdrachtgevers;
