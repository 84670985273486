import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './DashboardChangeNameAndFunction.css';
import { IoArrowBackSharp } from "react-icons/io5";
import { IoIosCloseCircleOutline } from "react-icons/io";

function DashboardChangeNameAndFunction({ onBack }) {
    const API_BASE_URL = process.env.REACT_APP_API_HOST;

    const [contactPersonen, setContactPersonen] = useState([]);
    const [selectedPerson, setSelectedPerson] = useState(null);
    const [newContactPersoonNaam, setNewContactPersoonNaam] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [lastModified, setLastModified] = useState(null);
    const [functieTitel, setFunctieTitel] = useState('');
    const inputRef = useRef(null); // Create a ref for the input field

    // Fetch contact personen on component mount
    useEffect(() => {
        const fetchContactPersonen = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/contactpersoon/all`);
                setContactPersonen(response.data);
            } catch (error) {
                console.error('Error fetching contact personen', error);
            }
        };

        fetchContactPersonen();
    }, [API_BASE_URL]);

    const handleAssignNewName = async () => {
        if (!selectedPerson || !newContactPersoonNaam) {
            alert('Please select a contact person and enter a new name.');
            return;
        }

        const updateData = {
            uuid: selectedPerson.uuid,
            contactPersoonNaam: newContactPersoonNaam,
            phoneNumber: selectedPerson.phoneNumber,
            assignedKlant: selectedPerson.assignedKlant,
            email: selectedPerson.email,
            functieTitel: functieTitel || selectedPerson.functieTitel,
        };

        try {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            await axios.put(`${API_BASE_URL}/contactpersoon/update`, updateData, config);

            const updatedPersonen = contactPersonen.map(person =>
                person.uuid === selectedPerson.uuid ? { ...person, contactPersoonNaam: newContactPersoonNaam, functieTitel } : person
            );
            setContactPersonen(updatedPersonen);

            setLastModified(newContactPersoonNaam);

            const currentIndex = contactPersonen.findIndex(person => person.uuid === selectedPerson.uuid);
            const nextPerson = contactPersonen[currentIndex + 1] || contactPersonen[0];
            setSelectedPerson(nextPerson);
            setFunctieTitel(nextPerson.functieTitel || '');
            setNewContactPersoonNaam(nextPerson.contactPersoonNaam || ''); // Pre-load next person's name
            setTimeout(() => {
                inputRef.current.focus(); // Set focus after the state update
            }, 0);
        } catch (error) {
            console.error('Error assigning new name', error);
        }
    };

    const goBackToPreviousPerson = () => {
        if (!selectedPerson) return;
        const currentIndex = contactPersonen.findIndex(person => person.uuid === selectedPerson.uuid);
        const previousPerson = contactPersonen[currentIndex - 1] || contactPersonen[contactPersonen.length - 1];
        setSelectedPerson(previousPerson);
        setFunctieTitel(previousPerson.functieTitel || '');
        setNewContactPersoonNaam(previousPerson.contactPersoonNaam || ''); // Pre-load previous person's name
        setTimeout(() => {
            inputRef.current.focus(); // Set focus after the state update
        }, 0);
    };

    const handleFunctieTitelChange = (title) => {
        setFunctieTitel(title);
    };

    const openModal = (person) => {
        setSelectedPerson(person);
        setFunctieTitel(person.functieTitel || '');
        setIsModalOpen(true);
        setNewContactPersoonNaam(person.contactPersoonNaam || ''); // Pre-load the person's name
        setTimeout(() => {
            inputRef.current.focus(); // Set focus when modal opens
        }, 0);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setNewContactPersoonNaam('');
        setLastModified(null);
    };

    // Event listener for keyboard actions
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault(); // Prevent the default form submit if inside a form
                handleAssignNewName();
            }
        };

        // Attach the event listener when the modal is open
        if (isModalOpen) {
            window.addEventListener('keydown', handleKeyDown);
        }

        // Cleanup event listener on unmount or when modal closes
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isModalOpen, selectedPerson, newContactPersoonNaam, functieTitel]); // Dependencies

    return (
        <div className="dashboard-change-name">
            <button onClick={onBack}><IoArrowBackSharp style={{ fontSize: '18px' }} /></button>
            <h2 className="dashboard-heading">Naam wijzigen</h2>

            <ul className="contact-list">
                {contactPersonen.map(person => (
                    <li
                        key={person.uuid}
                        className="contact-item"
                        onClick={() => openModal(person)}
                    >
                        <div><strong>Naam:</strong> {person.contactPersoonNaam}</div>
                        <div><strong>Functie:</strong> {person.functieTitel || 'Functie not assigned'}</div>
                        <div><strong>Bij begraafplaatsen:</strong> {person.assignedKlant}</div>
                    </li>
                ))}
            </ul>

            {isModalOpen && selectedPerson && (
                <div className="modal">
                    <div className="modal-content">
                        <button className="close-button" onClick={closeModal}>
                            <IoIosCloseCircleOutline style={{ fontSize: '25px' }}/>
                        </button>
                        <br />
                        <br />

                        <div>
                            <em onClick={goBackToPreviousPerson} style={{ cursor: 'pointer', color: 'blue' }}>
                                Laatst gewijzigd:
                            </em> {lastModified}
                        </div>
                        <br />

                        <h5>Wijzig naam en functie voor: </h5>
                        <h3>{selectedPerson.contactPersoonNaam}</h3>
                        <div className="form-group">
                            <label>Nieuw contactpersoon naam</label>
                            <input
                                type="text"
                                className="form-input"
                                placeholder="Enter new name"
                                value={newContactPersoonNaam}
                                onChange={(e) => setNewContactPersoonNaam(e.target.value)}
                                ref={inputRef} // Attach ref to the input
                            />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <strong>Functie:</strong>
                            <div className="functie-titel-options">
                                {['Alg. medewerker', 'Beheerder', 'Locatie manager'].map(title => (
                                    <div
                                        key={title}
                                        className={`functie-titel-option ${functieTitel === title ? 'selected' : ''}`}
                                        onClick={() => handleFunctieTitelChange(title)}
                                    >
                                        {title}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <button className="assign-button" onClick={handleAssignNewName}>
                            Opslaan en volgende
                        </button>

                        <div><strong>Bij begraafplaatsen:</strong> {selectedPerson.assignedKlant}</div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default DashboardChangeNameAndFunction;

