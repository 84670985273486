import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './DashboardVoertuigen.css';
import {IoArrowBackSharp} from "react-icons/io5";
import BackButton from "../../BackButton";

function DashboardVoertuigen({onBack}) {
    const [vacancies, setVacancies] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const API_BASE_URL = process.env.REACT_APP_API_HOST;
    const token = localStorage.getItem('token'); // Retrieve token from local storage

    useEffect(() => {
        const fetchVacancies = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await axios.get(`${API_BASE_URL}/voertuig/allavailable`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (response.status === 200) {
                    const fetchedVacancies = response.data;
                    setVacancies(fetchedVacancies);

                } else {
                    setError('Failed to fetch vacancies.');
                }
            } catch (err) {
                setError('An error occurred while fetching vacancies.');
            } finally {
                setLoading(false);
            }
        };

        fetchVacancies();
    }, [API_BASE_URL, token]);

    // Convert boolean values to human-readable text
    const booleanToText = (value) => (value ? 'Ja' : 'Nee');

    const handleEnable = async (uuid) => {
        try {
            const response = await axios.put(`${API_BASE_URL}/voertuig/enable/${uuid}`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                // Update local state
                setVacancies((prevVacancies) =>
                    prevVacancies.map((voertuig) =>
                        voertuig.uuid === uuid ? { ...voertuig, enabled: true } : voertuig
                    )
                );

            } else {
                setError('Failed to enable voertuig.');
            }
        } catch (err) {
            setError('An error occurred while enabling the voertuig.');
        }
    };

    const handleDisable = async (uuid) => {
        try {
            const response = await axios.put(`${API_BASE_URL}/voertuig/disable/${uuid}`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                // Update local state
                setVacancies((prevVacancies) =>
                    prevVacancies.map((voertuig) =>
                        voertuig.uuid === uuid ? { ...voertuig, enabled: false } : voertuig
                    )
                );

            } else {
                setError('Failed to disable voertuig.');
            }
        } catch (err) {
            setError('An error occurred while disabling the voertuig.');
        }
    };

    const handleDelete = async (uuid) => {
        if (window.confirm('Are you sure you want to delete this voertuig?')) {
            try {
                const response = await axios.delete(`${API_BASE_URL}/voertuig/${uuid}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (response.status === 200) {
                    // Update local state
                    setVacancies((prevVacancies) =>
                        prevVacancies.filter((voertuig) => voertuig.uuid !== uuid)
                    );

                } else {
                    setError('Failed to delete voertuig.');
                }
            } catch (err) {
                setError('An error occurred while deleting the voertuig.');
            }
        }
    };

    return (
        <section className="db-voertuigen-section">
            <BackButton onClick={onBack} />
            <br/>
            <h3>Voertuig Management</h3>

            {loading && <p>Loading vacancies...</p>}
            {error && <p className="error">{error}</p>}

            {!loading && !error && (
                <table className="db-voertuigen-table">
                    <thead className="db-voertuigen-thead">
                    <tr className="db-voertuigen-tr">
                        <th className="db-voertuigen-th">Aangemaakt:</th>
                        <th className="db-voertuigen-th">Bijnaam</th>
                        <th className="db-voertuigen-th">Kenteken</th>
                        <th className="db-voertuigen-th">Bouwjaar</th>
                        <th className="db-voertuigen-th">Actief</th>
                        <th className="db-voertuigen-th">Actie</th> {/* New column for actions */}
                    </tr>
                    </thead>
                    <tbody className="db-voertuigen-tbody">
                    {vacancies.length > 0 ? (
                        vacancies.map((voertuig) => (
                            <tr key={voertuig.uuid}>
                                <td className="db-voertuigen-td">{new Date(voertuig.createdAt).toLocaleString()}</td>
                                <td className="db-voertuigen-td">{voertuig.vehicleNickname}</td>
                                <td className="db-voertuigen-td">{voertuig.licensePlate}</td>
                                <td className="db-voertuigen-td">{voertuig.yearOfConstruction}</td>
                                <td className="db-voertuigen-td">{booleanToText(voertuig.enabled)}</td>
                                <td className="db-voertuigen-td">
                                    <button
                                        onClick={() =>
                                            voertuig.enabled
                                                ? handleDisable(voertuig.uuid)
                                                : handleEnable(voertuig.uuid)
                                        }
                                        className={voertuig.enabled ? "voertuigen-toggle-button disable" : "voertuigen-toggle-button enable"}
                                    >
                                        {voertuig.enabled ? "Disable" : "Enable"}
                                    </button>
                                    <button
                                        onClick={() => handleDelete(voertuig.uuid)}
                                        className="voertuigen-delete-button"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="11">Geen voertuigen beschikbaar</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            )}
        </section>
    );
}

export default DashboardVoertuigen;

