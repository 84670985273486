import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode'; // Correctly import jwt-decode
import './DashboardPlanningBuitendienst.css'; // Import the CSS file

function DashboardPlanningBuitendienst() {
    const API_BASE_URL = process.env.REACT_APP_API_HOST;
    const [planningData, setPlanningData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [grafTaakData, setGrafTaakData] = useState([]); // State for GrafTaak data
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userEmail, setUserEmail] = useState(null); // State to store user email

    useEffect(() => {
        const fetchPlanningAndCustomerData = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                setError('No token found. Please log in.');
                setLoading(false);
                return;
            }

            // Decode the token to get the user email
            const decoded = jwtDecode(token);
            const userEmail = decoded.sub; // Assuming 'sub' contains the user's email
            setUserEmail(userEmail);
            console.log("Decoded user email (sub):", userEmail); // Log decoded user email

            try {
                // Fetch all available planning items (PlanTaak)
                const planningResponse = await axios.get(`${API_BASE_URL}/plantaak/all`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                // Format the executionDate for each item in the planning data
                const formattedPlanningData = planningResponse.data.map(item => {
                    const formattedDate = new Date(item.executionDate).toLocaleDateString('nl-NL', {
                        weekday: 'long',
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric'
                    });

                    // Capitalize the first letter of the formatted date
                    const capitalizedDate = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

                    return {
                        ...item,
                        executionDate: capitalizedDate
                    };
                });

                setPlanningData(formattedPlanningData);
                console.log(planningResponse.data);


                // Fetch all available customer tasks (KlantTaak)
                const customerResponse = await axios.get(`${API_BASE_URL}/klanttaak/all`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setCustomerData(customerResponse.data);

                // Fetch all available GrafTaak
                const grafTaakResponse = await axios.get(`${API_BASE_URL}/graftaak/all`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setGrafTaakData(grafTaakResponse.data); // Store GrafTaak data

                setLoading(false);
            } catch (err) {
                console.error(err.response ? err.response.data : err.message);
                setError(err.response ? err.response.data.message : err.message);
                setLoading(false);
            }
        };

        fetchPlanningAndCustomerData();
    }, [API_BASE_URL]);

    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    if (error) {
        return <div className="error">Error: {error}</div>;
    }

    return (
        <div className="dashboard-planning">
            <h1 className="dashboard-title">Planning</h1>

            {/* Check if filtered planningData is empty */}
            <ul className="planning-list">
                {planningData
                    .filter(planningItem =>
                        planningItem.selectedMachinistEmail === userEmail ||
                        planningItem.selectedSecondManEmail === userEmail
                    ).length === 0 ? (
                    <div className="no-planning-message">
                        Je staat momenteel niet ingepland via software-begraafplaatswerken.nl.
                    </div>
                ) : (
                    planningData
                        .filter(planningItem =>
                            planningItem.selectedMachinistEmail === userEmail ||
                            planningItem.selectedSecondManEmail === userEmail
                        )
                        .map((planningItem) => (
                            <li key={planningItem.uuid} className="planning-item">
                                <div className="planning-details">
                                    <div className="planning-item-header">
                                        {planningItem.executionDate}
                                    </div>
                                    <span className="planning-detail"><strong>Auto:</strong> {planningItem.selectedVehicle}</span>
                                    <span className="planning-detail"><strong>Machinist:</strong> {planningItem.selectedMachinist}</span>
                                    <span className="planning-detail"><strong>Tweede man:</strong> {planningItem.selectedSecondMan}</span>
                                </div>
                                <div className="customer-list">
                                    <ul className="customer-list-items">
                                        {customerData
                                            .filter(customer => customer.planTaakUuid === planningItem.uuid)
                                            .map(filteredCustomer => (
                                                <li key={filteredCustomer.uuid} className="customer-list-item">
                                                    <span className="customer-name">{filteredCustomer.selectedCustomer}</span>
                                                    <div className="align-customer-time-row">
                                                        <span className="customer-time"><span>Aankomst:</span>  {filteredCustomer.selectedCustomerArrivalTime}</span>
                                                        <span className="customer-time"><span>Vertrek:</span> {filteredCustomer.selectedCustomerDepartureTime}</span>
                                                    </div>
                                                    <br />
                                                    <ul className="graf-task-list">
                                                        {grafTaakData
                                                            .filter(graf => graf.klantTaakUuid === filteredCustomer.uuid)
                                                            .map(graf => (
                                                                <li key={graf.uuid} className="graf-task-item">
                                                                    <span className="graf-task-item-title">
                                                                        <strong>{graf.selectedTaskModule}</strong>
                                                                        <span className="graf-task-item-grafnr">Graf: {graf.graveNumber}</span>
                                                                        {graf.selectedTaskDuration} minuten

                                                                    </span>
                                                                </li>
                                                            ))}
                                                        {grafTaakData.filter(graf => graf.klantTaakUuid === filteredCustomer.uuid).length === 0 && (
                                                            <li className="no-graf-task">No corresponding GrafTaak found.</li>
                                                        )}
                                                    </ul>
                                                </li>
                                            ))}
                                        {customerData.filter(customer => customer.planTaakUuid === planningItem.uuid).length === 0 && (
                                            <li className="no-customer">No corresponding KlantTaak found.</li>
                                        )}
                                    </ul>
                                </div>
                            </li>
                        ))
                )}
            </ul>
        </div>
    );
}

export default DashboardPlanningBuitendienst;





//TODO change allavailable to all
//TODO enable functionality