import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './DashboardPhoneBook.css';
import {IoIosCloseCircleOutline, IoIosContacts} from "react-icons/io";
import {RiContactsBook3Fill} from "react-icons/ri";

function DashboardPhoneBook() {
    const API_BASE_URL = process.env.REACT_APP_API_HOST;

    const [klanten, setKlanten] = useState([]);
    const [contactPersonen, setContactPersonen] = useState([]);
    const [selectedKlant, setSelectedKlant] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const klantRes = await axios.get(`${API_BASE_URL}/klant/all`);
                const contactPersoonRes = await axios.get(`${API_BASE_URL}/contactpersoon/all`);

                setKlanten(klantRes.data);
                setContactPersonen(contactPersoonRes.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [API_BASE_URL]);

    const handleShowModal = (klant) => {
        setSelectedKlant(klant);
        setShowModal(true);
    };

    const filteredContactPersonen = selectedKlant
        ? contactPersonen.filter(contact =>
            contact.assignedKlant && contact.assignedKlant.includes(selectedKlant.klantNaam)
        )
        : [];

    const filteredKlanten = klanten.filter(klant =>
        (klant.klantNaam && klant.klantNaam.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (klant.assignedOpdrachtgever && klant.assignedOpdrachtgever.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    return (
        <div className="dashboard-phonebook">
            {/*<h2 className="dashboard-phonebook-title">Begraafplaatsen & Contactpersonen</h2>*/}
            <br/>
            <br/>
            <h2>Snel zoeken</h2>
            <input
                type="text"
                placeholder="Zoek op kerkhof of persoon..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-bar"
            />
            <br/>

            <br/>
            <br/>
            {filteredKlanten.length > 0 ? (
                <ul className="klanten-lijst">
                    {filteredKlanten.map((klant) => (
                        <li
                            key={klant.uuid}
                            className={`klant-item ${selectedKlant && selectedKlant.uuid === klant.uuid ? 'selected' : ''}`}
                        >
                            {/*<strong>Naam:</strong>*/} <strong>{klant.klantNaam}</strong> <br />
                            {/*<strong>Adres:</strong>*/} {(klant.address && klant.housenumber) ?
                            `${klant.address} ${klant.housenumber}${klant.zipcode ? `, ${klant.zipcode}` : ''}${klant.city ? `, ${klant.city}` : ''}`
                            : 'Niet beschikbaar'}
                            <br />

                            {/*<strong>Telefoonnummer:</strong> {klant.phoneNumber || 'Niet beschikbaar'} <br />*/}
                            {/*<strong>Email:</strong> {klant.email || 'Niet beschikbaar'} <br />*/}

                            {/*<strong>Opdrachtgever:</strong> {klant.assignedOpdrachtgever || 'Niet beschikbaar'} <br />*/}
                            <button onClick={() => handleShowModal(klant)} className="view-contact-button">
                                <RiContactsBook3Fill style={{ fontSize: '1.5rem' }} />
                                ({contactPersonen.filter(contact => contact.assignedKlant && contact.assignedKlant.includes(klant.klantNaam)).length})
                            </button>

                        </li>
                    ))}
                </ul>
            ) : (
                <p>Geen klanten gevonden.</p>
            )}

            {/* Modal for Contactpersonen */}
            {showModal && selectedKlant && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <button className="close-modal" onClick={() => setShowModal(false)}><IoIosCloseCircleOutline style={{ fontSize: '25px' }}/></button>
                        <h2>Contactpersonen voor {selectedKlant.klantNaam}</h2>
                        <ul className="contact-personen-lijst">
                            {filteredContactPersonen.map((contact) => (
                                <li key={contact.uuid} className="contactpersoon-item">
                                    <strong>Naam:</strong> {contact.contactPersoonNaam} <br />
                                    {contact.functieTitel ? (
                                        <>
                                            <strong>Titel:</strong> {contact.functieTitel} <br />
                                        </>
                                    ) : null}
                                    <strong>Telefoonnummer:</strong>
                                    {contact.phoneNumber ? (
                                        <>
                                            <a href={`tel:${contact.phoneNumber}`}>
                                                {contact.phoneNumber}
                                            </a>
                                            {/*<button className="call-button" onClick={() => window.location.href = `tel:${contact.phoneNumber}`}>*/}
                                            {/*    Bel*/}
                                            {/*</button>*/}
                                        </>
                                    ) : (
                                        'Niet beschikbaar'
                                    )}
                                    <br />

                                    {/*<strong>Email:</strong>*/}
                                    {/*{contact.email ? (*/}
                                    {/*    <>*/}
                                    {/*        <a href={`mailto:${contact.email}`}>*/}
                                    {/*            {contact.email}*/}
                                    {/*        </a>*/}
                                    {/*        /!*<button className="email-button" onClick={() => window.location.href = `mailto:${contact.email}`}>*!/*/}
                                    {/*        /!*    Mailen*!/*/}
                                    {/*        /!*</button>*!/*/}
                                    {/*    </>*/}
                                    {/*) : (*/}
                                    {/*    'Niet beschikbaar'*/}
                                    {/*)}*/}
                                    {/*<br />*/}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}

            {selectedKlant && filteredContactPersonen.length === 0 && (
                <p>Geen contactpersonen gevonden voor deze klant.</p>
            )}
        </div>
    );
}

export default DashboardPhoneBook;


