import React, { useState } from 'react';
import axios from 'axios'; // Import axios
import './DashboardWijtmanMachineAanmaken.css';
import {IoArrowBackSharp} from "react-icons/io5"; // For custom styles

function DashboardWijtmanMachineAanmaken({onBack}) {
    const API_BASE_URL = process.env.REACT_APP_API_HOST; // Ensure this is set correctly

    // State for the form fields
    const [machineNickname, setMachineNickname] = useState('');
    const [machineType, setMachineType] = useState('');
    const [licenseplate, setLicenseplate] = useState('');
    const [yearOfConstruction, setYearOfConstruction] = useState('');
    const [error, setError] = useState(null); // State for error handling
    const [successMessage, setSuccessMessage] = useState(''); // State for success message

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        const machineData = {
            machineNickname,
            machineType,
            licenseplate,
            yearOfConstruction
        };

        try {
            const response = await axios.post(`${API_BASE_URL}/machine/create`, machineData);
            console.log(response.data); // Handle response here (e.g., show success message)
            setSuccessMessage('Machine successfully created!');
            // Reset the form after submission
            setMachineNickname('');
            setMachineType('');
            setLicenseplate('');
            setYearOfConstruction('');
        } catch (error) {
            console.error('Error creating machine:', error);
            setError('Failed to create machine. Please try again.');
        }
    };

    return (
        <div className="DashboardWijtmanMachineAanmaken">
            <button
                onClick={onBack}
                style={{
                    background: 'darkblue',
                    fontSize: '2.5rem',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    padding: '10px' // Optional for spacing
                }}>
                <IoArrowBackSharp style={{ fontSize: '2.5rem' }} />
                Terug
            </button>
            <br/>
            <h1>Aanmaken</h1>
            {error && <p className="error-message">{error}</p>}
            {successMessage && <p className="success-message">{successMessage}</p>}
            <form onSubmit={handleSubmit}>
                <div>
                    <label>
                        Bijnaam:
                        <input
                            type="text"
                            value={machineNickname}
                            onChange={(e) => setMachineNickname(e.target.value)}
                        />
                    </label>
                </div>

                <div>
                    <label>
                        Kenteken:
                        <input
                            type="text"
                            value={licenseplate}
                            onChange={(e) => setLicenseplate(e.target.value)}
                        />
                    </label>
                </div>

                <div className="machine-type-selection">
                    <p>Soort machine:</p>
                    <div className="machine-type-boxes">
                        {['BOKI', 'Nimos', 'Shovel', 'Rupskraan'].map((type) => (
                            <div
                                key={type}
                                className={`box ${machineType === type ? 'selected' : ''}`}
                                onClick={() => setMachineType(type)}
                            >
                                {type}
                            </div>
                        ))}
                    </div>
                </div>

                <div>
                    <label>
                        Bouwjaar:
                        <input
                            type="text"
                            value={yearOfConstruction}
                            onChange={(e) => setYearOfConstruction(e.target.value)}
                        />
                    </label>
                </div>

                <button type="submit" className="db-machine-aanmaken-verstuur-button">Verstuur</button>
            </form>
        </div>
    );
}

export default DashboardWijtmanMachineAanmaken;
