import React, { useState } from 'react';
import './Operator.css';
import { FaUserPlus, FaUserEdit, FaTruck, FaTasks, FaBuilding, FaUsers, FaCog, FaUserFriends, FaHammer } from "react-icons/fa";
import { MdPersonAdd, MdWork, MdOutlineBuild } from "react-icons/md";
import { GiTruck, GiFactory } from "react-icons/gi";
import { AiOutlineUsergroupAdd, AiOutlineContacts } from "react-icons/ai";

// Import components
import DashboardWijtmanMachineAanmaken from "./Aanmaken/DashboardWijtmanMachineAanmaken";
import DashboardWijtmanVoertuigAanmaken from "./Aanmaken/DashboardWijtmanVoertuigAanmaken";
import DashboardWijtmanPersoneelAanmaken from "./Aanmaken/DashboardWijtmanPersoneelAanmaken";
import DashboardWijtmanKlantAanmaken from "./Aanmaken/DashboardWijtmanKlantAanmaken";
import DashboardContactPersoonAanmaken from "./Aanmaken/DashboardContactPersoonAanmaken";
import DashboardTaakAanmaken from "./Aanmaken/DashboardTaakAanmaken";
import DashboardOpdrachtgeverAanmaken from "./Aanmaken/DashboardOpdrachtgeverAanmaken";

import DashboardVoertuigen from "./Beheren/DashboardVoertuigen";
import DashboardMachines from "./Beheren/DashboardMachines";
import DashboardPersoneelsleden from "./Beheren/DashboardPersoneelsleden";
import DashboardKlanten from "./Beheren/DashboardKlanten";
import DashboardContactPersonen from "./Beheren/DashboardContactPersonen";
import DashboardTaken from "./Beheren/DashboardTaken";
import DashboardOpdrachtgevers from "./Beheren/DashboardOpdrachtgevers";

function Operator() {
    const [currentView, setCurrentView] = useState('home');

    const handleBackToHome = () => {
        setCurrentView('home');
    };

    const renderCurrentView = () => {
        switch (currentView) {
            case 'Machine':
                return <DashboardWijtmanMachineAanmaken onBack={handleBackToHome} />;
            case 'Voertuig':
                return <DashboardWijtmanVoertuigAanmaken onBack={handleBackToHome} />;
            case 'Personeel':
                return <DashboardWijtmanPersoneelAanmaken onBack={handleBackToHome} />;
            case 'Klant':
                return <DashboardWijtmanKlantAanmaken onBack={handleBackToHome} />;
            case 'Contactpersoon':
                return <DashboardContactPersoonAanmaken onBack={handleBackToHome} />;
            case 'Taak':
                return <DashboardTaakAanmaken onBack={handleBackToHome} />;
            case 'Opdrachtgever':
                return <DashboardOpdrachtgeverAanmaken onBack={handleBackToHome} />;
            case 'Voertuigen':
                return <DashboardVoertuigen onBack={handleBackToHome} />;
            case 'Machines':
                return <DashboardMachines onBack={handleBackToHome} />;
            case 'Personeelsleden':
                return <DashboardPersoneelsleden onBack={handleBackToHome} />;
            case 'Klanten':
                return <DashboardKlanten onBack={handleBackToHome} />;
            case 'Contactpersonen':
                return <DashboardContactPersonen onBack={handleBackToHome} />;
            case 'Taken':
                return <DashboardTaken onBack={handleBackToHome} />;
            case 'Opdrachtgevers':
                return <DashboardOpdrachtgevers onBack={handleBackToHome} />;
            default:
                return (
                    <div className="home-screen">
                        <h2 className="welcome-message">Welkom,</h2>
                        <p className="action-prompt">Selecteer een actie om uit te voeren:</p>
                        <div className="section-container-align-row">
                        <div className="section">
                            <h3 className="section-title">Aanmaken</h3>
                            <ul>
                                <li className="dashboard-operator-align-pictogram-text-column" onClick={() => setCurrentView('Personeel')}>
                                    <FaUserPlus style={{ fontSize: '2rem' }} />
                                    <p className="dashboard-operator-picto-text-fontsize">Personeel</p>
                                </li>
                                <li className="dashboard-operator-align-pictogram-text-column" onClick={() => setCurrentView('Voertuig')}>
                                    <GiTruck style={{ fontSize: '2rem' }} />
                                    <p className="dashboard-operator-picto-text-fontsize">Voertuig</p>
                                </li>
                                <li className="dashboard-operator-align-pictogram-text-column" onClick={() => setCurrentView('Machine')}>
                                    <GiFactory style={{ fontSize: '2rem' }} />
                                    <p className="dashboard-operator-picto-text-fontsize">Machine</p>
                                </li>
                                <li className="dashboard-operator-align-pictogram-text-column" onClick={() => setCurrentView('Taak')}>
                                    <FaTasks style={{ fontSize: '2rem' }} />
                                    <p className="dashboard-operator-picto-text-fontsize">Taak</p>
                                </li>
                                <li className="dashboard-operator-align-pictogram-text-column" onClick={() => setCurrentView('Opdrachtgever')}>
                                    <FaBuilding style={{ fontSize: '2rem' }} />
                                    <p className="dashboard-operator-picto-text-fontsize">Opdrachtgever</p>
                                </li>
                                <li className="dashboard-operator-align-pictogram-text-column" onClick={() => setCurrentView('Klant')}>
                                    <AiOutlineUsergroupAdd style={{ fontSize: '2rem' }} />
                                    <p className="dashboard-operator-picto-text-fontsize">Klant</p>
                                </li>
                                <li className="dashboard-operator-align-pictogram-text-column" onClick={() => setCurrentView('Contactpersoon')}>
                                    <AiOutlineContacts style={{ fontSize: '2rem' }} />
                                    <p className="dashboard-operator-picto-text-fontsize">Contactpersoon</p>
                                </li>
                            </ul>
                        </div>

                        <div className="section">
                            <h3 className="section-title">Beheren</h3>
                            <ul>
                                <li className="dashboard-operator-align-pictogram-text-column" onClick={() => setCurrentView('Personeelsleden')}>
                                    <FaUserEdit style={{ fontSize: '2rem' }} />
                                    <p className="dashboard-operator-picto-text-fontsize">Personeelsleden</p>
                                </li>
                                <li className="dashboard-operator-align-pictogram-text-column" onClick={() => setCurrentView('Voertuigen')}>
                                    <FaTruck style={{ fontSize: '2rem' }} />
                                    <p className="dashboard-operator-picto-text-fontsize">Voertuigen</p>
                                </li>
                                <li className="dashboard-operator-align-pictogram-text-column" onClick={() => setCurrentView('Machines')}>
                                    <MdOutlineBuild style={{ fontSize: '2rem' }} />
                                    <p className="dashboard-operator-picto-text-fontsize">Machines</p>
                                </li>
                                <li className="dashboard-operator-align-pictogram-text-column" onClick={() => setCurrentView('Taken')}>
                                    <MdWork style={{ fontSize: '2rem' }} />
                                    <p className="dashboard-operator-picto-text-fontsize">Taken</p>
                                </li>
                                <li className="dashboard-operator-align-pictogram-text-column" onClick={() => setCurrentView('Opdrachtgevers')}>
                                    <FaBuilding style={{ fontSize: '2rem' }} />
                                    <p className="dashboard-operator-picto-text-fontsize">Opdrachtgevers</p>
                                </li>
                                <li className="dashboard-operator-align-pictogram-text-column" onClick={() => setCurrentView('Klanten')}>
                                    <FaUsers style={{ fontSize: '2rem' }} />
                                    <p className="dashboard-operator-picto-text-fontsize">Klanten</p>
                                </li>
                                <li className="dashboard-operator-align-pictogram-text-column" onClick={() => setCurrentView('Contactpersonen')}>
                                    <FaUserFriends style={{ fontSize: '2rem' }} />
                                    <p className="dashboard-operator-picto-text-fontsize">Contactpersonen</p>
                                </li>
                            </ul>
                        </div>
                        </div>
                    </div>
                );
        }
    };

    return (
        <div className="operator-dashboard">
            <div className="operator-view-container">
                {renderCurrentView()}
            </div>
        </div>
    );
}

export default Operator;
