import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './DashboardPersoneelsleden.css';
import {IoArrowBackSharp} from "react-icons/io5";
import BackButton from "../../BackButton";

function DashboardPersoneelsleden({onBack}) {
    const [vacancies, setVacancies] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const API_BASE_URL = process.env.REACT_APP_API_HOST;
    const token = localStorage.getItem('token'); // Retrieve token from local storage

    useEffect(() => {
        const fetchVacancies = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await axios.get(`${API_BASE_URL}/personeel/allavailable`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (response.status === 200) {
                    const fetchedVacancies = response.data;
                    setVacancies(fetchedVacancies);

                } else {
                    setError('Failed to fetch vacancies.');
                }
            } catch (err) {
                setError('An error occurred while fetching vacancies.');
            } finally {
                setLoading(false);
            }
        };

        fetchVacancies();
    }, [API_BASE_URL, token]);

    // Convert boolean values to human-readable text
    const booleanToText = (value) => (value ? 'Ja' : 'Nee');

    const handleEnable = async (uuid) => {
        try {
            const response = await axios.put(`${API_BASE_URL}/personeel/enable/${uuid}`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                // Update local state
                setVacancies((prevVacancies) =>
                    prevVacancies.map((personeel) =>
                        personeel.uuid === uuid ? { ...personeel, enabled: true } : personeel
                    )
                );

            } else {
                setError('Failed to enable personeel.');
            }
        } catch (err) {
            setError('An error occurred while enabling the personeel.');
        }
    };

    const handleDisable = async (uuid) => {
        try {
            const response = await axios.put(`${API_BASE_URL}/personeel/disable/${uuid}`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                // Update local state
                setVacancies((prevVacancies) =>
                    prevVacancies.map((personeel) =>
                        personeel.uuid === uuid ? { ...personeel, enabled: false } : personeel
                    )
                );

            } else {
                setError('Failed to disable personeel.');
            }
        } catch (err) {
            setError('An error occurred while disabling the personeel.');
        }
    };



    const handleDelete = async (uuid) => {
        if (window.confirm('Are you sure you want to delete this personeel?')) {
            try {
                const response = await axios.delete(`${API_BASE_URL}/personeel/${uuid}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (response.status === 200) {
                    // Update local state
                    setVacancies((prevVacancies) =>
                        prevVacancies.filter((personeel) => personeel.uuid !== uuid)
                    );

                } else {
                    setError('Failed to delete personeel.');
                }
            } catch (err) {
                setError('An error occurred while deleting the personeel.');
            }
        }
    };

    return (
        <section className="db-personeelsleden-section">
            <BackButton onClick={onBack} />
            <br/>
            <h3>Management</h3>

            {loading && <p>Loading vacancies...</p>}
            {error && <p className="error">{error}</p>}

            {!loading && !error && (
                <table className="db-personeelsleden-table">
                    <thead className="db-personeelsleden-thead">
                    <tr className="db-personeelsleden-tr">
                        {/*<th className="db-personeelsleden-th">Aangemaakt:</th>*/}
                        <th className="db-personeelsleden-th">Naam</th>
                        <th className="db-personeelsleden-th">Email</th>
                        <th className="db-personeelsleden-th">Rijbewijs</th>
                        <th className="db-personeelsleden-th">Rol</th>
                        <th className="db-personeelsleden-th">Actief</th>
                        <th className="db-personeelsleden-th">Actie</th> {/* New column for actions */}
                    </tr>
                    </thead>
                    <tbody className="db-personeelsleden-tbody">
                    {vacancies.length > 0 ? (
                        vacancies.map((personeel) => (
                            <tr key={personeel.uuid}>
                                {/*<td className="db-personeelsleden-td">{new Date(personeel.createdAt).toLocaleString()}</td>*/}
                                <td className="db-personeelsleden-td">{personeel.phoneNumber}</td>
                                {/*<td>{personeel.name}</td>*/}
                                <td className="db-personeelsleden-td">{personeel.email}</td>
                                <td className="db-personeelsleden-td">{personeel.driversLicense}</td>
                                <td className="db-personeelsleden-td">{personeel.jobRole}</td>
                                <td className="db-personeelsleden-td">{booleanToText(personeel.enabled)}</td>
                                <td className="db-personeelsleden-td">
                                    <button
                                        onClick={() =>
                                            personeel.enabled
                                                ? handleDisable(personeel.uuid)
                                                : handleEnable(personeel.uuid)
                                        }
                                        className={personeel.enabled ? "personeelsleden-toggle-button disable" : "personeelsleden-toggle-button enable"}
                                    >
                                        {personeel.enabled ? 'Disable' : 'Enable'}
                                    </button>
                                    <button
                                        onClick={() => handleDelete(personeel.uuid)}
                                        className="personeelsleden-delete-button"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="11">Geen personeel beschikbaar</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            )}
        </section>
    );
}

export default DashboardPersoneelsleden;

