import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Voorrijkosten.css';

function Voorrijkosten({ onClose }) {
    const API_BASE_URL = process.env.REACT_APP_API_HOST;

    const [finances, setFinances] = useState([]);
    const [upToTwentyFiveKm, setUpToTwentyFiveKm] = useState('');
    const [upToFourtyKm, setUpToFourtyKm] = useState('');
    const [upToSixtyKm, setUpToSixtyKm] = useState('');

    useEffect(() => {
        axios.get(`${API_BASE_URL}/finance/all`)
            .then(response => {
                setFinances(response.data);
                if (response.data.length > 0) {
                    const firstFinance = response.data[0];
                    setUpToTwentyFiveKm(firstFinance.upToTwentyFiveKm || '');
                    setUpToFourtyKm(firstFinance.upToFourtyKm || '');
                    setUpToSixtyKm(firstFinance.upToSixtyKm || '');
                }
            })
            .catch(error => console.error('Er is een fout opgetreden bij het ophalen van de finances:', error));
    }, [API_BASE_URL]);

    const updateFirstFinance = () => {
        if (finances.length === 0) {
            alert('Geen finances om bij te werken.');
            return;
        }

        const updatedFinance = {
            ...finances[0],
            upToTwentyFiveKm,
            upToFourtyKm,
            upToSixtyKm
        };

        const token = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };

        axios.put(`${API_BASE_URL}/finance/update`, updatedFinance, { headers })
            .then(() => {
                alert('De eerste finance is succesvol bijgewerkt!');
                setFinances(prev => prev.map((finance, index) =>
                    index === 0 ? updatedFinance : finance
                ));
            })
            .catch(error => console.error('Er is een fout opgetreden bij het updaten van de eerste finance:', error));
    };

    return (
        <div className="modal-overlay">
            <div className="voorrijkosten modal-content">
                <button className="modal-close" onClick={onClose}>&times;</button>
                <h1 className="voorrijkosten-title">Voorrijkosten</h1>
                <div className="voorrijkosten-form">
                    <label className="voorrijkosten-label">
                        Tot 25 km:
                        <input
                            type="number"
                            className="voorrijkosten-input"
                            value={upToTwentyFiveKm}
                            onChange={e => setUpToTwentyFiveKm(e.target.value)}
                            required
                        />
                    </label>
                    <label className="voorrijkosten-label">
                        Tot 40 km:
                        <input
                            type="number"
                            className="voorrijkosten-input"
                            value={upToFourtyKm}
                            onChange={e => setUpToFourtyKm(e.target.value)}
                            required
                        />
                    </label>
                    <label className="voorrijkosten-label">
                        Tot 60 km:
                        <input
                            type="number"
                            className="voorrijkosten-input"
                            value={upToSixtyKm}
                            onChange={e => setUpToSixtyKm(e.target.value)}
                            required
                        />
                    </label>
                    <button className="voorrijkosten-button" onClick={updateFirstFinance}>Bijwerken</button>
                </div>
            </div>
        </div>
    );
}

export default Voorrijkosten;


