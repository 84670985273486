import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './DashboardTaken.css';
import {IoArrowBackSharp} from "react-icons/io5";
import BackButton from "../../BackButton";

function DashboardTaken({onBack}) {
    const [tasks, setTasks] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const API_BASE_URL = process.env.REACT_APP_API_HOST;
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchTasks = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await axios.get(`${API_BASE_URL}/taak/allavailable`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (response.status === 200) {
                    setTasks(response.data);
                } else {
                    setError('Failed to fetch tasks.');
                }
            } catch (err) {
                setError('An error occurred while fetching tasks.');
            } finally {
                setLoading(false);
            }
        };

        const fetchCustomers = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/klant/all`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                if (response.status === 200) {
                    setCustomers(response.data);
                } else {
                    setError('Failed to fetch customers.');
                }
            } catch (err) {
                setError('An error occurred while fetching customers.');
            }
        };

        fetchTasks();
        fetchCustomers();
    }, [API_BASE_URL, token]);

    const handleCustomerChange = (event) => {
        setSelectedCustomer(event.target.value);
    };

    const booleanToText = (value) => (value ? 'Ja' : 'Nee');

    const handleEnable = async (uuid) => {
        try {
            const response = await axios.put(`${API_BASE_URL}/taak/enable/${uuid}`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                setTasks((prevTasks) =>
                    prevTasks.map((taak) =>
                        taak.uuid === uuid ? { ...taak, enabled: true } : taak
                    )
                );
            } else {
                setError('Failed to enable task.');
            }
        } catch (err) {
            setError('An error occurred while enabling the task.');
        }
    };

    const handleDisable = async (uuid) => {
        try {
            const response = await axios.put(`${API_BASE_URL}/taak/disable/${uuid}`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                setTasks((prevTasks) =>
                    prevTasks.map((taak) =>
                        taak.uuid === uuid ? { ...taak, enabled: false } : taak
                    )
                );
            } else {
                setError('Failed to disable task.');
            }
        } catch (err) {
            setError('An error occurred while disabling the task.');
        }
    };

    const handleDelete = async (uuid) => {
        if (window.confirm('Are you sure you want to delete this task?')) {
            try {
                const response = await axios.delete(`${API_BASE_URL}/taak/${uuid}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (response.status === 200) {
                    setTasks((prevTasks) =>
                        prevTasks.filter((taak) => taak.uuid !== uuid)
                    );
                } else {
                    setError('Failed to delete task.');
                }
            } catch (err) {
                setError('An error occurred while deleting the task.');
            }
        }
    };

    const handleCreateTasksForCustomer = async () => {
        const emptyTasks = tasks.filter((taak) => !taak.customerTaskList);

        try {
            await Promise.all(
                emptyTasks.map((task) =>
                    axios.post(
                        `${API_BASE_URL}/taak/create`,
                        {
                            customerTaskList: selectedCustomer,
                            taskGroup: task.taskGroup,
                            taskName: task.taskName,
                            taskDuration: task.taskDuration,
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            },
                        }
                    )
                )
            );

            const refreshedTasks = await axios.get(`${API_BASE_URL}/taak/allavailable`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            setTasks(refreshedTasks.data);
        } catch (error) {
            setError('An error occurred while creating tasks for the customer.');
        }
    };

    const filteredTasks = tasks.filter((taak) =>
        selectedCustomer === "" || taak.customerTaskList === selectedCustomer
    );

    return (
        <section className="tasks-section">
            <BackButton onClick={onBack} />
            <br/>
            <h3 className="tasklist-section-title">Taaklijsten aanmaken</h3>

            {loading && <p className="loading-text">Loading...</p>}
            {error && <p className="error-text">{error}</p>}

            <div className="tasklist-customer-dropdown">
                <label htmlFor="customer-select" className="tasklist-dropdown-label">Selecteer Klant:</label>
                <select
                    id="customer-select"
                    onChange={handleCustomerChange}
                    value={selectedCustomer}
                    className="dropdown-select"
                >
                    <option value="">Selecteer een klant</option>
                    {customers.map((customer) => (
                        <option key={customer.uuid} value={customer.klantNaam}>
                            {customer.klantNaam}
                        </option>
                    ))}
                </select>
            </div>

            {!loading && !error && (
                <>
                    <table className="tasklist-table">
                        <thead className="tasklist-thead">
                        <tr className="tasklist-tr">
                            <th className="tasklist-th">Klantnaam</th>
                            <th className="tasklist-th">Taakgroep</th>
                            <th className="tasklist-th">Taaknaam</th>
                            <th className="tasklist-th">Taakduur</th>
                            <th className="tasklist-th">Actief</th>
                            <th className="tasklist-th">Actie</th>
                        </tr>
                        </thead>
                        <tbody className="tasklist-tbody">
                        {filteredTasks.length > 0 ? (
                            filteredTasks.map((taak) => (
                                <tr key={taak.uuid} className="table-row">
                                    <td className="tasklist-table-cell">{taak.customerTaskList}</td>
                                    <td className="tasklist-table-cell">{taak.taskGroup}</td>
                                    <td className="tasklist-table-cell">{taak.taskName}</td>
                                    <td className="tasklist-table-cell">{taak.taskDuration} mins</td>
                                    <td className="tasklist-table-cell">{booleanToText(taak.enabled)}</td>
                                    <td className="tasklist-table-cell">
                                        <div className="tasklist-align-buttons-row">
                                        <button
                                            onClick={() =>
                                                taak.enabled
                                                    ? handleDisable(taak.uuid)
                                                    : handleEnable(taak.uuid)
                                            }
                                            className={`toggle-button ${taak.enabled ? "disable" : "enable"}`}
                                        >
                                            {taak.enabled ? 'Disable' : 'Enable'}
                                        </button>
                                        <button
                                            onClick={() => handleDelete(taak.uuid)}
                                            className="delete-button"
                                        >
                                            Delete
                                        </button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6" className="no-tasks-message">Geen taken beschikbaar</td>
                            </tr>
                        )}
                        </tbody>
                    </table>

                    {filteredTasks.length === 0 && (
                        <button onClick={handleCreateTasksForCustomer} className="create-tasks-button">
                            Create Tasks for {selectedCustomer}
                        </button>
                    )}
                </>
            )}
        </section>
    );
}

export default DashboardTaken;
