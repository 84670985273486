import React, { useState } from 'react';
import './Planner.css';
import {FaCalendarAlt, FaListUl, FaRegCalendarPlus, FaUserPlus} from "react-icons/fa";
import { GiTruck, GiFactory } from "react-icons/gi";
import DashboardWijtmanPlanningMaken from "./DashboardWijtmanPlanningMaken";
import PlantakenOverzicht from "./PlantakenOverzicht";



function Planner() {
    const [currentView, setCurrentView] = useState('home');

    const goToPlanlijst = () => {
        setCurrentView('Planlijst');
    };

    const handleBackToHome = () => {
        setCurrentView('home');
    };



    const renderCurrentView = () => {
        switch (currentView) {
            case 'PlantaakMaken':
                return <DashboardWijtmanPlanningMaken onBack={handleBackToHome} goToPlanlijst={goToPlanlijst} />;
            case 'Planlijst':
                return <PlantakenOverzicht onBack={handleBackToHome} />;

            default:
                return (
                    <div className="home-screen">
                        <h2 className="welcome-message">Welkom,</h2>
                        <p className="action-prompt">Selecteer een actie om uit te voeren:</p>
                        <div className="planner-section-container-align-row">
                            <div className="planner-section">
                                <h3 className="planner-section-title">Planbeheer</h3>
                                <ul>
                                    <li className="dashboard-planner-align-pictogram-text-column" onClick={() => setCurrentView('PlantaakMaken')}>
                                        <FaRegCalendarPlus style={{ fontSize: '2rem' }} />
                                        <p className="dashboard-planner-picto-text-fontsize">Plantaak maken</p>
                                    </li>
                                    <li className="dashboard-planner-align-pictogram-text-column" onClick={() => setCurrentView('Planlijst')}>
                                        <FaCalendarAlt style={{ fontSize: '2rem' }} />
                                        <p className="dashboard-planner-picto-text-fontsize">Overzicht plantaken</p>
                                    </li>

                                </ul>
                            </div>

                        </div>
                    </div>
                );
        }
    };

    return (
        <div className="planner-dashboard">
            <div className="planner-view-container">
                {renderCurrentView()}
            </div>
        </div>
    );
}

export default Planner;
