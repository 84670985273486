import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {IoArrowBackSharp} from "react-icons/io5";

function DashboardChangeKlantName({ onBack }) {
    const API_BASE_URL = process.env.REACT_APP_API_HOST;
    const [klanten, setKlanten] = useState([]);
    const [selectedKlant, setSelectedKlant] = useState(null);
    const [klantNaam, setKlantNaam] = useState('');

    // Haal alle klanten op bij het laden van de component
    useEffect(() => {
        axios.get(`${API_BASE_URL}/klant/all`)
            .then(response => {
                setKlanten(response.data);
            })
            .catch(error => {
                console.error('Er is een fout opgetreden bij het ophalen van de klanten:', error);
            });
    }, [API_BASE_URL]);

    // Functie om de klantnaam bij te werken voor de geselecteerde klant
    const updateKlantNaam = (klantId) => {
        // Haal het token uit localStorage
        const token = localStorage.getItem('token');

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Voeg het token toe aan de Authorization header
        };

        // Zoek de huidige klant in de klantenlijst
        const klant = klanten.find(k => k.uuid === klantId);

        if (!klant) {
            console.error('Klant niet gevonden!');
            return;
        }

        // Update alleen de klantnaam, de rest van de klantdata blijft gelijk
        const updatedKlant = {
            ...klant, // Behoud alle bestaande klantdata
            klantNaam: klantNaam // Update alleen klantNaam
        };

        axios.put(`${API_BASE_URL}/klant/update`, updatedKlant, { headers })
            .then(response => {
                alert('Klantnaam succesvol bijgewerkt!');
                // Optioneel: Refresh de klantenlijst of update lokaal de klantdata
            })
            .catch(error => {
                console.error('Er is een fout opgetreden bij het updaten van de klantnaam:', error);
            });
    };

    return (
        <div className="DashboardChangeKlantName">
            <button onClick={onBack}><IoArrowBackSharp style={{ fontSize: '18px' }} /></button>
            <h1>Wijzigen van Klantnaam</h1>

            {/* Dropdown om klant te selecteren */}
            <select onChange={e => {
                const selectedKlantId = e.target.value;
                setSelectedKlant(selectedKlantId);

                // Vind de geselecteerde klant en stel de klantNaam in
                const klant = klanten.find(k => k.uuid === selectedKlantId);
                if (klant) {
                    setKlantNaam(klant.klantNaam);
                }
            }} value={selectedKlant}>
                <option value="">Selecteer een klant</option>
                {klanten.map(klant => (
                    <option key={klant.uuid} value={klant.uuid}>
                        {klant.klantNaam}
                    </option>
                ))}
            </select>

            {/* Inputveld voor het aanpassen van de klantnaam */}
            {selectedKlant && (
                <div>
                    <label>
                        Klantnaam:
                        <input
                            type="text"
                            value={klantNaam}
                            onChange={e => setKlantNaam(e.target.value)}
                        />
                    </label>
                    <br />
                    <button onClick={() => updateKlantNaam(selectedKlant)}>
                        Bijwerken
                    </button>
                </div>
            )}
        </div>
    );
}

export default DashboardChangeKlantName;

