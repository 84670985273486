import React, { useState } from 'react';
import axios from 'axios'; // Import axios
import './DashboardWijtmanPersoneelAanmaken.css';
import {IoArrowBackSharp} from "react-icons/io5"; // Include custom styles

function DashboardWijtmanPersoneelAanmaken({onBack}) {
    const API_BASE_URL = process.env.REACT_APP_API_HOST; // Ensure this is set in your .env file

    // State for the form fields
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [driversLicense, setDriversLicense] = useState('B');
    const [jobRole, setJobRole] = useState([]); // Manage as an array for multiple roles
    const [email, setEmail] = useState([]); // Manage as an array for multiple roles

    const [error, setError] = useState(null); // For error handling
    const [successMessage, setSuccessMessage] = useState(''); // For success feedback

    // Toggle role selection allowing multiple job roles to be selected
    const toggleRole = (role) => {
        setJobRole((prevRoles) =>
            prevRoles.includes(role)
                ? prevRoles.filter((r) => r !== role) // Unselect the role if it's already selected
                : [...prevRoles, role] // Add the role if it's not selected yet
        );
    };

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Basic validation
        if (!name || !phoneNumber) {
            setError('Name and phone number are required.');
            return;
        }

        // Create a single string for job roles
        const jobRoleString = jobRole.join(', '); // Join roles with a comma and space

        const personnelData = {
            name,
            phoneNumber,
            driversLicense,
            jobRole: jobRoleString, // Send the concatenated string instead of an array
            email,
        };


        try {
            console.log("Sending personnel data:", personnelData); // Debugging log
            const response = await axios.post(`${API_BASE_URL}/personeel/create`, personnelData);
            console.log(response.data);
            setSuccessMessage('Personnel successfully created!');
            // Reset form fields after submission
            setName('');
            setPhoneNumber('');
            setDriversLicense('B');
            setJobRole([]);
            setEmail('');

        } catch (error) {
            console.error('Error creating personnel:', error);
            setError('Failed to create personnel. Please try again.');
        }
    };

    return (
        <div className="DashboardWijtmanPersoneel">
            <button
                onClick={onBack}
                style={{
                    background: 'darkblue',
                    fontSize: '2.5rem',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    padding: '10px' // Optional for spacing
                }}>
                <IoArrowBackSharp style={{ fontSize: '2.5rem' }} />
                Terug
            </button>
            <br/>            <h1>Aanmaken</h1>
            <br />
            <br />
            <br />
            {error && <p className="error-message">{error}</p>}
            {successMessage && <p className="success-message">{successMessage}</p>}
            <form onSubmit={handleSubmit}>
                <div>
                    <label>
                        Naam:
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </label>
                </div>

                <div>
                    <label>
                        Telefoonnummer:
                        <input
                            type="text"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                    </label>
                </div>
                <div>
                    <label>
                        Email:
                        <input
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </label>
                </div>
                <div className="license-selection">
                    <p>Rijbewijs:</p>
                    <div className="license-boxes">
                        <div
                            className={`box ${driversLicense === 'B' ? 'selected' : ''}`}
                            onClick={() => setDriversLicense('B')}
                        >
                            B
                        </div>
                        <div
                            className={`box ${driversLicense === 'BE' ? 'selected' : ''}`}
                            onClick={() => setDriversLicense('BE')}
                        >
                            BE
                        </div>
                    </div>
                </div>
                <br />
                <div className="role-selection">
                    <p>Rol:</p>
                    <div className="role-boxes">
                        <div
                            className={`box ${jobRole.includes('Machinist') ? 'selected' : ''}`}
                            onClick={() => toggleRole('Machinist')}
                        >
                            Machinist
                        </div>
                        <div
                            className={`box ${jobRole.includes('Tweede man') ? 'selected' : ''}`}
                            onClick={() => toggleRole('Tweede man')}
                        >
                            Tweede man
                        </div>
                    </div>
                </div>

                <button type="submit" className="db-personeel-verstuur-button">Aanmaken</button>
            </form>
        </div>
    );
}

export default DashboardWijtmanPersoneelAanmaken;

