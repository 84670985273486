import React, {useContext} from 'react';
import './App.css';
import Header from "./components/Header";
import Homepage from "./components/Homepage";
import {Navigate, Route, Routes} from "react-router-dom";
import Footer from "./components/Footer";
import useScrollToTop from "./components/hooks/useScrollToTop";
import Login from "./components/Login";
import {AuthContext} from "./context/AuthContext";
import Dashboard from "./components/Dashboard/Dashboard";

function App() {
    const { isAuth } = useContext(AuthContext);
    useScrollToTop();

    return (
        <div>
            <Header />
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/dashboard" element={isAuth ? <Dashboard /> : <Navigate to="/login" />}/>






                {/*<Route path="/uitgelegd/web-app" element={<ExplainedWebApp />} />*/}
                {/*<Route path="/uitgelegd/artificial-intelligence" element={<ExplainedArtificialIntelligence />} />*/}
                {/*<Route path="/uitgelegd/api" element={<ExplainedAPI />} />*/}


                {/*<Route path="/werk" element={<Personeel />} />*/}
                {/*<Route path="/personeel" element={<Werk />} />*/}







            </Routes>
            <Footer />
        </div>
    );
}

export default App;


