import React, { useEffect, useState } from 'react';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import './Header.css';
import Logo from '../assets/Logo.png';
import weverlingwijtmandashboardmenucontrast from '../assets/weverlingwijtmandashboardmenucontrast.png';
import {IoEnterOutline} from "react-icons/io5";


function Header() {
    const navigate = useNavigate();
    const location = useLocation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isHeaderVisible, setIsHeaderVisible] = useState(true); // State for header visibility


    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const handleLinkClick = (id) => {
        closeMenu();
    };

    function handleRedirectRondleiding() {
        closeMenu();
        navigate('/open-sollicitatie');
    }

    useEffect(() => {
        // Check if the current path is '/dashboard'
        if (location.pathname === '/dashboard') {
            setIsHeaderVisible(false); // Hide header for dashboard route
        } else {
            setIsHeaderVisible(true); // Show header for other routes
        }
    }, [location.pathname]); // Run effect on path change

    return (
        <header className={`m-header ${isHeaderVisible ? '' : 'hidden'}`}>
            <Link to="/" id="hero-section" className="title-and-menu-link">
                {/*<img src={weverlingwijtmandashboardmenucontrast} alt="Software Begraafplaatswerken Logo" className="header-logo" onClick={handleLinkClick}/>*/}
                {/*<h1>software-begraafplaatswerken.nl</h1>*/}
            </Link>

            {/*<div className="title-and-menu">*/}
            {/*    <div className={`menu-toggle ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>*/}
            {/*        <div className="bar"/>*/}
            {/*        <div className="bar"/>*/}
            {/*        <div className="bar"/>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <nav className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
                <div className="play-nav-open-menu">

                {/*<ul>*/}
                {/*    /!*<li>*!/*/}
                {/*    /!*    <Link to="/begrippen-lijst"*!/*/}
                {/*    /!*          className={`nav-link ${location.pathname === '/begrippen-lijst' ? 'active' : ''}`}*!/*/}
                {/*    /!*          onClick={() => handleLinkClick('')}>*!/*/}
                {/*    /!*        Begrippenlijst*!/*/}
                {/*    /!*    </Link>*!/*/}
                {/*    /!*</li>*!/*/}
                {/*    <li>*/}
                {/*        <Link to="/talent-pool"*/}
                {/*              className={`nav-link ${location.pathname === '/talent-pool' ? 'active' : ''}`}*/}
                {/*              onClick={() => handleLinkClick('')}>*/}
                {/*            Talent pool*/}
                {/*        </Link>*/}
                {/*    </li>*/}
                {/*</ul>*/}
                <ul>
                    {/*<li>*/}
                    {/*    <Link to="/vacature-bank"*/}
                    {/*          className={`nav-link ${location.pathname === '/vacature-bank' ? 'active' : ''}`}*/}
                    {/*          onClick={() => handleLinkClick('')}>*/}
                    {/*        Vacature bank*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <Link to="/vacature-toevoegen"*/}
                    {/*          className={`nav-link ${location.pathname === '/vacature-toevoegen' ? 'active' : ''}`}*/}
                    {/*          onClick={() => handleLinkClick('')}>*/}
                    {/*        Vacature toevoegen*/}
                    {/*    </Link>*/}
                    {/*</li>*/}

                    {/*<li>*/}
                    {/*    <Link to="/open-sollicitatie"*/}
                    {/*          className={`nav-link ${location.pathname === '/open-sollicitatie' ? 'active' : ''}`}*/}
                    {/*          onClick={() => handleLinkClick('')}>*/}
                    {/*        Open sollicitatie*/}
                    {/*    </Link>*/}
                    {/*</li>*/}

                    {/*<li>*/}
                    {/*    <Link to="/personeel"*/}
                    {/*          className={`nav-link ${location.pathname === '/personeel' ? 'active' : ''}`}*/}
                    {/*          onClick={() => handleLinkClick('')}>*/}
                    {/*        Personeel*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <Link to="/werk"*/}
                    {/*          className={`nav-link ${location.pathname === '/werk' ? 'active' : ''}`}*/}
                    {/*          onClick={() => handleLinkClick('')}>*/}
                    {/*        Werk*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                </ul>
                {/*<ul>*/}
                {/*    <li>*/}
                {/*        <button className="action-button" onClick={handleRedirectRondleiding}>*/}
                {/*            Actie knop/!*<IoEnterOutline className="rondleiding-icon"/>*!/*/}
                {/*        </button>*/}
                {/*    </li>*/}
                {/*</ul>*/}
                {/*<ul className="header-login-container-ul">*/}
                {/*    <li className="header-login-container-li">*/}
                {/*        <button classname="header-login-button">*/}
                {/*        <Link to="/login"*/}
                {/*              className={`nav-link ${location.pathname === '/login' ? 'active' : ''}`}*/}
                {/*              onClick={() => handleLinkClick('')}>*/}
                {/*            Inloggen*/}
                {/*        </Link>*/}
                {/*        </button>*/}

                {/*    </li>*/}
                {/*</ul>*/}
                </div>

            </nav>

        </header>
    );


}

export default Header;
