import React from 'react';
import './BackButton.css';
import {IoArrowBackSharp} from "react-icons/io5";

function onBackButton({ onClick }) {

    return (
        <div className="onBackButton">
            <button
                onClick={onClick} // Attach the onClick prop to handle clicks
                style={{
                    background: 'darkblue',
                    fontSize: '1.7rem',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    padding: '10px' // Optional for spacing
                }}>
                <IoArrowBackSharp style={{ fontSize: '1.7rem' }} />
                Terug
            </button>
        </div>
    );
}

export default onBackButton;









