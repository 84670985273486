import React, { useEffect, useState } from "react";
import axios from "axios";
import './WerkBonnen.css';
import {IoArrowBackSharp} from "react-icons/io5";

function WerkBonnen({onBack}) {
    const API_BASE_URL = process.env.REACT_APP_API_HOST;
    const [customerData, setCustomerData] = useState([]);
    const [planningData, setPlanningData] = useState({});
    const [grafTaakData, setGrafTaakData] = useState([]);
    const [klantData, setKlantData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem("token");
            if (!token) {
                setError("No token found. Please log in.");
                setLoading(false);
                return;
            }

            try {
                const klantTaakResponse = await axios.get(`${API_BASE_URL}/klanttaak/all`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setCustomerData(klantTaakResponse.data);

                const uniquePlanTaakUuids = [...new Set(klantTaakResponse.data.map(item => item.planTaakUuid))];
                const planTaakResponses = await Promise.all(
                    uniquePlanTaakUuids.map(uuid =>
                        axios.get(`${API_BASE_URL}/plantaak/${uuid}`, {
                            headers: { Authorization: `Bearer ${token}` }
                        })
                    )
                );

                const planTaakDataMap = {};
                planTaakResponses.forEach(response => {
                    const planTaak = response.data;
                    const formattedDate = new Date(planTaak.executionDate).toLocaleDateString("nl-NL", {
                        weekday: "long",
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                    });
                    planTaakDataMap[planTaak.uuid] = {
                        ...planTaak,
                        executionDate: formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)
                    };
                });

                setPlanningData(planTaakDataMap);

                const grafTaakResponse = await axios.get(`${API_BASE_URL}/graftaak/all`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setGrafTaakData(grafTaakResponse.data);

                const financeResponse = await axios.get(`${API_BASE_URL}/finance/all`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const financeData = financeResponse.data[0];
                if (financeData) {
                    localStorage.setItem("upToTwentyFiveKm", financeData.upToTwentyFiveKm);
                    localStorage.setItem("upToFourtyKm", financeData.upToFourtyKm);
                    localStorage.setItem("upToSixtyKm", financeData.upToSixtyKm);
                }

                const klantResponse = await axios.get(`${API_BASE_URL}/klant/all`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setKlantData(klantResponse.data);

                setLoading(false);
            } catch (err) {
                setError(err.response ? err.response.data.message : err.message);
                setLoading(false);
            }
        };

        fetchData();
    }, [API_BASE_URL]);

    const getFeeByDistance = (selectedCustomer) => {
        const klant = klantData.find(k => k.klantNaam === selectedCustomer);
        if (klant && klant.afstandVanafZaak != null) {
            const afstand = klant.afstandVanafZaak;

            if (afstand < 25) return localStorage.getItem("upToTwentyFiveKm");
            else if (afstand >= 25 && afstand < 40) return localStorage.getItem("upToFourtyKm");
            else if (afstand >= 40 && afstand < 60) return localStorage.getItem("upToSixtyKm");
        }
        return null;
    };

    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    if (error) {
        return <div className="error">Error: {error}</div>;
    }

    return (
        <div className="WerkBon">
            <button
                onClick={onBack}
                style={{
                    background: 'darkblue',
                    fontSize: '2.5rem',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    padding: '10px' // Optional for spacing
                }}>
                <IoArrowBackSharp style={{ fontSize: '2.5rem' }} />
                Terug
            </button>
            <br/>
            <h2 className="WerkBon-title">Facturen</h2>
            {/*<h3 className="missing-information">ontbrekende informatie</h3>*/}
            <br/>
            <br/>
            <div className="WerkBon-container">
                {customerData.map((klantTaak, index) => {
                    const planTaak = planningData[klantTaak.planTaakUuid];
                    const executionDate = planTaak ? planTaak.executionDate : "Datum niet gevonden";
                    const selectedCustomer = klantTaak.selectedCustomer || "Klant niet gevonden";

                    const relevantGrafTaaken = grafTaakData.filter(grafTaak => grafTaak.klantTaakUuid === klantTaak.uuid);
                    const completedGrafTaakCount = relevantGrafTaaken.length;

                    const distanceFee = getFeeByDistance(selectedCustomer);

                    return (
                        <div key={index} className="WerkBon-invoice">
                            <h3>{selectedCustomer}</h3>
                            <p><strong>Datum:</strong> {executionDate}</p>
                            <p><strong>Aantal Taken:</strong> {completedGrafTaakCount}</p>
                            <div>
                                <br/>
                                <strong>Overzicht:</strong>
                                <table className="WerkBon-taskTable">
                                    <thead>
                                    <tr>
                                        <th>Taakmodule</th>
                                        <th>Taakduur</th>
                                        <th>EUR</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {relevantGrafTaaken.map((grafTaak, grafIndex) => (
                                        <tr key={grafIndex}>
                                            <td>{grafTaak.selectedTaskModule}</td>
                                            <td>{grafTaak.selectedTaskDuration} minuten</td>
                                            <td>€XX</td>
                                        </tr>
                                    ))}

                                    </tbody>

                                </table>
                                <br/>
                                <p><strong>Voorrijkosten:</strong> {distanceFee ? `${distanceFee} EUR` : "Onbekend"}</p>
                                <br/>
                                <p><strong>Totaal excl btw:</strong>
                                    <br/>
                                    ((total(EUR)) + Voorrijkosten)</p>
                                <br/>
                                <p><strong>Totaal incl btw:</strong>
                                    <br/>
                                    ((total(EUR)) + Voorrijkosten) * 1.21</p>

                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default WerkBonnen;
