import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './UserManagement.css';

function UserManagement() {
    const API_BASE_URL = process.env.REACT_APP_API_HOST;

    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [authorities, setAuthorities] = useState([]);
    const [newRole, setNewRole] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [hideDisabled, setHideDisabled] = useState(false); // New state for hiding disabled users

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const token = localStorage.getItem('token');
                const res = await axios.get(`${API_BASE_URL}/users`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUsers(res.data);
            } catch (error) {
                setError('Error fetching users. Please try again.');
                console.error('Error fetching users:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchUsers();
    }, [API_BASE_URL]);

    const updateUser = async (email) => {
        const token = localStorage.getItem('token');
        const updatedUser = await axios.get(`${API_BASE_URL}/users/${email}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        setUsers(prevUsers =>
            prevUsers.map(user => (user.email === email ? updatedUser.data : user))
        );
        setAuthorities(updatedUser.data.authorities || []);
    };

    const handleEnableUser = async (email) => {
        try {
            const token = localStorage.getItem('token');
            await axios.put(`${API_BASE_URL}/users/enable/${email}`, null, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            updateUser(email);
        } catch (error) {
            setError('Error enabling user. Please try again.');
            console.error('Error enabling user:', error);
        }
    };

    const handleDisableUser = async (email) => {
        try {
            const token = localStorage.getItem('token');
            await axios.put(`${API_BASE_URL}/users/disable/${email}`, null, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            updateUser(email);
        } catch (error) {
            setError('Error disabling user. Please try again.');
            console.error('Error disabling user:', error);
        }
    };

    const handleAssignRole = async (email, newRole) => {
        if (!newRole.trim()) {
            console.error('Role cannot be empty');
            return;
        }
        try {
            const token = localStorage.getItem('token');
            await axios.put(`${API_BASE_URL}/users/adduserrole/${email}`,
                newRole,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            await updateUser(email);
            setNewRole('');
        } catch (error) {
            setError('Error assigning authorities. Please try again.');
            console.error('Error assigning authorities:', error.response?.data || error);
        }
    };

    const handleRemoveRole = async (email, authorities) => {
        if (!authorities.trim()) {
            console.error('Role cannot be empty');
            return;
        }
        const confirmRemoval = window.confirm(`Are you sure you want to remove the authorities "${authorities}"?`);
        if (!confirmRemoval) return;

        try {
            const token = localStorage.getItem('token');
            await axios.put(`${API_BASE_URL}/users/removeuserrole/${email}`,
                authorities,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            await updateUser(email);
        } catch (error) {
            setError('Error removing authorities. Please try again.');
            console.error('Error removing authorities:', error.response?.data || error);
        }
    };

    const handleSelectUser = (user) => {
        setSelectedUser(user);
        setAuthorities(user.authorities || []);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    const filteredUsers = hideDisabled ? users.filter(user => user.enabled) : users; // Filter users

    return (
        <div className="user-management">
            <h2>User Management</h2>
            {error && <div className="error-message">{error}</div>}
            <div className="filter-section">
                <label>
                    <input
                        type="checkbox"
                        checked={hideDisabled}
                        onChange={() => setHideDisabled(!hideDisabled)}
                    />
                    Hide Disabled Users
                </label>
            </div>
            <div className="user-list">
                <h3>Users</h3>
                <ul>
                    {filteredUsers.map(user => (
                        <li key={user.email}>
                            <strong>{user.firstName} {user.lastName}</strong> ({user.email}, {user.phoneNumber}) <br />
                            Status: {user.enabled ? 'Enabled' : 'Disabled'} <br />
                            Roles: {user.authorities ? user.authorities.join(', ') : 'No authorities assigned'} <br />
                            <button onClick={() => handleSelectUser(user)}>Manage</button>
                            {user.enabled ? (
                                <button onClick={() => handleDisableUser(user.email)}>Disable</button>
                            ) : (
                                <button onClick={() => handleEnableUser(user.email)}>Enable</button>
                            )}
                        </li>
                    ))}
                </ul>
            </div>

            {selectedUser && (
                <div className="user-details">
                    <h3>Manage Roles for {selectedUser.firstName} {selectedUser.lastName}</h3>
                    <ul>
                        {authorities.map(authorities => (
                            <li key={authorities}>
                                {authorities}
                                <button onClick={() => handleRemoveRole(selectedUser.email, authorities)}>Remove Role</button>
                            </li>
                        ))}
                    </ul>
                    <input
                        type="text"
                        placeholder="New Role"
                        value={newRole}
                        onChange={(e) => setNewRole(e.target.value)}
                    />
                    <button onClick={() => handleAssignRole(selectedUser.email, newRole)}>Assign Role</button>
                </div>
            )}
        </div>
    );
}

export default UserManagement;
