// LoginSuccessful.js

import React from 'react';
import weverlingwijtmandashboardmenucontrast from "../assets/weverlingwijtmandashboardmenucontrast.png";
import './LoginSuccessful.css';

function LoginSuccessful() {


    return (
        <div className="login-successful-container">
            <img src={weverlingwijtmandashboardmenucontrast} alt="Weverling Wijtman Logo" className="login-successful-logo" />
            <h2 className="login-successful-message">Welkom terug!</h2>
            <p className="login-successful-subtext">Je wordt doorverwezen naar het dashboard...</p>
        </div>
    );
}

export default LoginSuccessful;
