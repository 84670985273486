import React, { useState } from 'react';
import axios from 'axios'; // Import axios
import './DashboardTaakAanmaken.css';
import {IoArrowBackSharp} from "react-icons/io5"; // For custom styles

function DashboardTaakAanmaken({onBack}) {
    const API_BASE_URL = process.env.REACT_APP_API_HOST;

    // State for the form fields
    const [taskGroup, setTaskGroup] = useState(''); // Renamed to setTaakType for consistency
    const [taskName, setTaskName] = useState(''); // Taaknaam as a text field
    const [taskDuration, setTaskDuration] = useState(''); // Taaknaam as a text field

    const [error, setError] = useState(null); // State for error handling
    const [successMessage, setSuccessMessage] = useState(''); // State for success message

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Log the taak data or send it to a server
        const taskData = {
            taskGroup,
            taskName,
            taskDuration
        };

        try {
            console.log('Submitting Task Data:', taskData);
            const response = await axios.post(`${API_BASE_URL}/taak/create`, taskData);
            console.log(response.data); // Handle response here (e.g., show success message)
            setSuccessMessage('Task successfully created!');
            // Reset the form after submission
            setTaskGroup('');
            setTaskName('');
            setTaskDuration('');
        } catch (error) {
            console.error('Error creating task:', error);
            setError('Failed to create task. Please try again.');
        }
    };

    return (
        <div className="DashboardTaakAanmaken">
            <button
                onClick={onBack}
                style={{
                    background: 'darkblue',
                    fontSize: '2.5rem',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    padding: '10px' // Optional for spacing
                }}>
                <IoArrowBackSharp style={{ fontSize: '2.5rem' }} />
                Terug
            </button>
            <h1>Aanmaken</h1>
            {error && <p className="error-message">{error}</p>}
            {successMessage && <p className="success-message">{successMessage}</p>}
            <form onSubmit={handleSubmit}>
                <div className="taak-type-selection">
                    <p>Taakgroep:</p>
                    <div className="taak-type-boxes">
                        {['Monument', 'Graf', 'Kelder', 'Urn', 'Overige'].map((type) => (
                            <div
                                key={type}
                                className={`box ${taskGroup === type ? 'selected' : ''}`}
                                onClick={() => setTaskGroup(type)}
                            >
                                {type}
                            </div>
                        ))}
                    </div>
                </div>

                <div>
                    <label>
                        Taaknaam:
                        <input
                            type="text" // Changed from number to text input
                            value={taskName}
                            onChange={(e) => setTaskName(e.target.value)}
                            required
                        />
                    </label>

                </div>

                <div>
                    <label>
                        Taakduur in minuten:
                        <input
                            type="text" // Changed from number to text input
                            value={taskDuration}
                            onChange={(e) => setTaskDuration(e.target.value)}
                            required
                        />
                    </label>

                </div>

                <button type="submit" className="dashboard-taak-aanmaken-button">Aanmaken</button>
            </form>
        </div>
    );
}

export default DashboardTaakAanmaken;
